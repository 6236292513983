<template>
  <PMain v-model="themeData" :dataKey="key">
    <!-- START CATEGORY BLOCK -->
    <v-row v-if="themeData.categoryBlock">
      <v-col>
        <v-tabs
          v-model="selectedTab"
          :height="50"
          v-bind="themeData.categoryBlock.data.attr"
          :background-color="
            themeData.categoryBlock.data.attr.style.backgroundColor
          "
          :style="{
            ...themeData.categoryBlock.data.attr.style,
            position: themeData.categoryBlock.data.fixed ? 'fixed' : 'relative',
            zIndex: themeData.categoryBlock.data.fixed ? 1 : 1,
          }"
        >
          <v-tab
            v-for="(category, idx) in categories"
            :key="idx"
            :href="'#category-' + idx"
            @click="handleClickTab(category)"
            :active-class="
              themeData.categoryBlock.data.nodes.tabActive.data.attr.class
            "
          >
            {{ category.name }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row
      v-if="themeData.categoryBlock && themeData.categoryBlock.data.fixed"
      style="height: 50px"
    >
      <v-col>
        <br />
      </v-col>
    </v-row>
    <!-- END CATEGORY BLOCK -->

    <v-row v-if="isProductLoading">
      <v-col v-for="i in 5" cols="12" :key="i">
        <v-skeleton-loader type="card" height="150"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        v-for="(product, idx) in products"
        :key="`product_${idx}`"
        cols="12"
      >
        <v-card
          v-if="themeData.productListBlock"
          v-bind="themeData.productListBlock.data.attr"
        >
          <v-card-text>
            <div class="d-flex justify-space-between">
              <div
                v-if="
                  imageBlockData.showImage && product.menu_product.product.image
                "
                :style="`width:${
                  100 - themeData.productListBlock.data.contentWidth
                }%`"
              >
                <div
                  :style="{
                    margin: imageBlockData.attr.style.margin,
                  }"
                >
                  <v-img
                    :src="product.menu_product.product.image"
                    :lazy-src="product.menu_product.product.image"
                    v-bind="imageBlockData.attr"
                    :style="
                      pick(imageBlockData.attr.style, [
                        'borderRadius',
                        'padding',
                      ])
                    "
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
              </div>
              <div
                :style="`width:${
                  imageBlockData.showImage && product.menu_product.product.image
                    ? themeData.productListBlock.data.contentWidth
                    : 100
                }%`"
              >
                <div class="d-flex justify-space-between">
                  <div
                    v-bind="
                      themeData.productListBlock.data.nodes.title.data.attr
                    "
                  >
                    {{ product.menu_product.product.name }}
                  </div>
                  <v-spacer />
                  <div v-if="getItemShopStock(product)">
                    <PButton
                      v-if="
                        themeData.productListBlock.data.nodes.plusIcon.data
                          .active
                      "
                      v-model="
                        themeData.productListBlock.data.nodes.plusIcon.data
                      "
                      @click="handleAddToCart(product)"
                    />
                  </div>
                  <div v-else>
                    <v-img src="/asset/out-of-stock.png" width="48" />
                  </div>
                </div>

                <div
                  v-if="
                    themeData.productListBlock.data.nodes.description &&
                    themeData.productListBlock.data.nodes.description.data
                      .active
                  "
                  v-bind="
                    themeData.productListBlock.data.nodes.description.data.attr
                  "
                >
                  {{ product.menu_product.product.description_2 }}
                </div>

                <div
                  v-bind="themeData.productListBlock.data.nodes.price.data.attr"
                >
                  € {{ product.price }}
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ProductDetail
      v-model="productId"
      :show="productDialog"
      @closed="productDialog = false"
      :currentPageAttr="themeData"
    />
  </PMain>
</template>

<script>
import { mapActions, mapState } from "vuex";
import _ from "lodash";
import ProductDetail from "./ProductDetail.vue";
import PMain from "../components/PMain.vue";
import PButton from "../components/_render/PButton.vue";

export default {
  data: () => ({
    key: "menu",
    productDialog: false,
    productId: null,
    themeData: {},
    selectedTab: 3,
  }),
  components: {
    ProductDetail,
    PMain,
    PButton,
  },
  methods: {
    async handleClickTab(category) {
      // this.$router.push(`/menu/${category.id}`);

      await this.getProductAll({
        menu_id: this.defaultMenuId,
        category_id: category.id,
      });
    },

    ...mapActions({
      getCategoryAll: "category/getAll",
      getProductAll: "product/getAll",
    }),
    applyTabContainer(newValue) {
      document.body.style.setProperty(
        "--tabContainerBackgroundColor",
        newValue.categoryBlock.data.attr.style.backgroundColor
      );
      document.body.style.setProperty(
        "--tabContainerBorderRadius",
        newValue.categoryBlock.data.attr.style.borderRadius
      );
      document.body.style.setProperty(
        "--tabContainerPadding",
        newValue.categoryBlock.data.attr.style.padding
      );
      document.body.style.setProperty(
        "--tabContainerMargin",
        newValue.categoryBlock.data.attr.style.margin
      );
      document.body.style.setProperty(
        "--tabContainerColor",
        newValue.categoryBlock.data.attr.style.color
      );
    },
    applyTab(newValue) {
      document.body.style.setProperty(
        "--tabBackgroundColor",
        newValue.categoryBlock.data.nodes.tab.data.attr.style.backgroundColor
      );

      document.body.style.setProperty(
        "--tabBorderRadius",
        newValue.categoryBlock.data.nodes.tab.data.attr.style.borderRadius
      );

      document.body.style.setProperty(
        "--tabPadding",
        newValue.categoryBlock.data.nodes.tab.data.attr.style.padding
      );

      document.body.style.setProperty(
        "--tabMargin",
        newValue.categoryBlock.data.nodes.tab.data.attr.style.margin
      );

      document.body.style.setProperty(
        "--tabColor",
        newValue.categoryBlock.data.nodes.tab.data.attr.style.color
      );

      document.body.style.setProperty(
        "--tabFontSize",
        newValue.categoryBlock.data.nodes.tab.data.attr.style.fontSize
      );
    },
    applyTabActive(newValue) {
      if (!newValue.categoryBlock.data.nodes.tabActive.data.underline) {
        document.body.style.setProperty("--tabActiveUnderline", "0px");
      } else {
        document.body.style.setProperty(
          "--tabActiveUnderline",
          `${newValue.categoryBlock.data.nodes.tabActive.data.underlineSize}px`
        );
        document.body.style.setProperty(
          "--tabActiveUnderlineColor",
          newValue.categoryBlock.data.nodes.tabActive.data.underlineColor
        );
      }

      document.body.style.setProperty(
        "--tabActiveBackgroundColor",
        newValue.categoryBlock.data.nodes.tabActive.data.attr.style
          .backgroundColor
      );

      document.body.style.setProperty(
        "--tabActiveBorderRadius",
        newValue.categoryBlock.data.nodes.tabActive.data.attr.style.borderRadius
      );

      document.body.style.setProperty(
        "--tabActivePadding",
        newValue.categoryBlock.data.nodes.tabActive.data.attr.style.padding
      );

      document.body.style.setProperty(
        "--tabActiveMargin",
        newValue.categoryBlock.data.nodes.tabActive.data.attr.style.margin
      );

      document.body.style.setProperty(
        "--tabActiveColor",
        newValue.categoryBlock.data.nodes.tabActive.data.attr.style.color
      );
      document.body.style.setProperty(
        "--tabActiveFontSize",
        newValue.categoryBlock.data.nodes.tabActive.data.attr.style.fontSize
      );
    },
    handleAddToCart(productId) {
      this.productDialog = true;
      this.productId = productId;
    },
    pick(object, key) {
      return _.pick(object, key);
    },
    getItemShopStock(item) {
      let stock = false;
      if (item.menu_product) {
        let stockShops = JSON.parse(item.menu_product.product.status);
        stockShops.map((stockStatus) => {
          if (stockStatus.stock_status == process.env.VUE_APP_SHOP_ID + "-1") {
            stock = true;
          }
        });
      }
      return stock;
    },
  },

  watch: {
    async categoryId(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.getProductAll({
          menu_id: this.defaultMenuId,
          category_id: newValue,
        });
      }
    },
    themeData: {
      handler(newValue) {
        this.applyTabContainer(newValue);
        this.applyTab(newValue);
        this.applyTabActive(newValue);
      },
      deep: true,
    },

    async defaultMenuId(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.getCategoryAll({
          menu_id: this.defaultMenuId,
        });

        await this.getProductAll({
          menu_id: this.defaultMenuId,
          category_id: this.getInitialCategoryId,
        });
      }
    },
  },
  async mounted() {
    await this.getCategoryAll({
      menu_id: this.defaultMenuId,
    });

    await this.getProductAll({
      menu_id: this.defaultMenuId,
      category_id: this.getInitialCategoryId,
    });
  },
  computed: {
    ...mapState({
      products: (state) => state.product.data,
      isProductLoading: (state) => state.product.isLoading,
      categories: (state) => state.category.data,
      isCategoryLoading: (state) => state.category.isLoading,
    }),

    imageBlockData() {
      if (this.themeData.productListBlock) {
        return this.themeData.productListBlock.data.nodes.image.data;
      }
      return {};
    },
    titleBlockData() {
      if (this.themeData.productListBlock) {
        return this.themeData.productListBlock.data.nodes.title.data;
      }
      return {};
    },

    categoryId() {
      const { params } = this.$route;
      return params.category_id;
    },

    defaultMenuId() {
      if (this.themeData && this.themeData.categoryBlock) {
        const { menuId } = this.themeData.categoryBlock.data;
        return menuId;
      }
      return false;
    },

    getInitialCategoryId() {
      if (this.categoryId) {
        return this.categoryId;
      }
      if (this.categories.length > 0) {
        return this.categories[0].menu_category_id;
      }
      return null;
    },
  },
};
</script>

<style>
.v-slide-group__next,
.v-slide-group__prev,
.v-tab {
  background: var(--tabBackgroundColor, #1b1b1b);
  border-radius: var(--tabBorderRadius, 50px);
  color: var(--tabColor, #ffffff) !important;
  padding: var(--tabPadding, 0px 0px 0px 0px) !important;
  margin: var(--tabMargin, 0px 0px 0px 0px) !important;
  font-size: var(--tabFontSize, 16px) !important;
}
.v-tab--active {
  background: var(--tabActiveBackgroundColor, #1b1b1b);
  border-radius: var(--tabActiveBorderRadius, 50px);
  color: var(--tabActiveColor, #ffffff) !important;
  padding: var(--tabActivePadding, 0px 0px 0px 0px) !important;
  margin: var(--tabActiveMargin, 0px 0px 0px 0px) !important;
  font-size: var(--tabActiveFontSize, 16px) !important;
  /* text-decoration: underline !important; */
  border-bottom: var(--tabActiveUnderline, 4px) solid
    var(--tabActiveUnderlineColor, #000000) !important;
}
.v-tabs {
}
.v-tabs-slider-wrapper {
  display: none !important;
  border: 0px !important;
}

.v-slide-group__prev--disabled {
  display: none !important;
}
</style>
