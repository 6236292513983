import common from "../helpers/common";

export default {
  computed: {
    getNow() {
      return common.getNow();
    },
  },
  methods: {
    formatPrice(value) {
      return Number(value).toFixed(2);
    },
  },
};
