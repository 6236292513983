/* eslint-disable */
/* eslint-disable no-param-reassign */
<template>
  <PMain v-model="themeData" :dataKey="key">
    <!-- TOTAL ORDER ITEM BLOCK -->
    <v-alert v-if="!$store.getters['cart/tableId']" prominent type="warning">
      <v-row align="center">
        <v-col class="grow"> Please confirm your table! </v-col>
        <v-col class="shrink">
          <v-btn @click="$router.push('/table')">Confirm</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <template
      v-if="
        themeData.totalOrderedItem && themeData.totalOrderedItem.data.active
      "
    >
      <v-card v-bind="themeData.totalOrderedItem.data.attr">
        <v-card-title>
          {{ themeData.totalOrderedItem.data.text }} ({{ cartProducts.length }})
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-list
            :style="
              pick(
                themeData.totalOrderedItem.data.attr.style,
                'backgroundColor'
              )
            "
          >
            <v-list-item-group v-for="(prod, key) in cartProducts" :key="key">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="d-flex justify-space-between">
                      <div
                        class="d-flex justify-space-between"
                        style="width: 55%"
                      >
                        <div @click="showOrHide(key)">
                          {{ prod.name }}
                        </div>
                      </div>

                      <div class="d-flex" style="width: 15%">
                        <PButton
                          @click="handleAddQty(prod)"
                          v-model="
                            themeData.totalOrderedItem.data.nodes
                              .spinnerPlusIcon.data
                          "
                          v-if="
                            themeData.totalOrderedItem.data.nodes
                              .spinnerPlusIcon.data.active
                          "
                        />
                        <div
                          style="
                            text-align: center;
                            padding: 0px 5px;
                            margin: 0;
                          "
                        >
                          {{ prod.quantity }}
                        </div>
                        <PButton
                          @click="handleReduceQty(prod)"
                          v-model="
                            themeData.totalOrderedItem.data.nodes
                              .spinnerMinusIcon.data
                          "
                          v-if="
                            themeData.totalOrderedItem.data.nodes
                              .spinnerMinusIcon.data.active
                          "
                        />
                      </div>
                      <div class="d-flex justify-end" style="width: 30%">
                        <div style="width: 80%; text-align: right">
                          <b>
                            €{{
                              parseFloat(
                                prod.totalPrice * prod.quantity
                              ).toFixed(2)
                            }}
                          </b>
                        </div>
                        <div
                          style="
                            width: 20%;
                            margin-left: 5px;
                            text-align: right;
                          "
                        >
                          <PButton
                            v-if="
                              themeData.totalOrderedItem.data.nodes
                                .deleteItemIcon.data.active
                            "
                            v-model="
                              themeData.totalOrderedItem.data.nodes
                                .deleteItemIcon.data
                            "
                            @click="deleteProduct(prod.id)"
                          />
                        </div>
                      </div>
                    </div>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <div
                      v-if="
                        prod.optionalGroups && prod.optionalGroups.length > 0
                      "
                    >
                      <div
                        v-for="(option, kk) in prod.optionalGroups"
                        :key="kk"
                        style="margin: 5px 0px 0px 10px"
                      >
                        {{ option.name }} (+€{{ formatPrice(option.price) }})
                      </div>
                    </div>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    class="pt-2"
                    v-if="
                      dataFromDb.menu.productDetailBlock.data.nodes
                        .productRemark.data.active
                    "
                  >
                    <v-textarea
                      label="Product Remarks"
                      outlined
                      solo
                      auto-grow
                      v-model="prod.remarks"
                      rows="1"
                    />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </v-list-item-group>

            <v-list-item-group v-if="cartProducts.length == 0">
              <v-list-item class="d-flex">
                {{ themeData.totalOrderedItem.data.textNoProduct }}
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </template>
    <!-- TOTAL ORDER ITEM BLOCK -->

    <!-- SUB TOTAL BLOCK -->
    <template
      v-if="
        themeData.subTotalBlock &&
        themeData.subTotalBlock.data &&
        themeData.subTotalBlock.data.active &&
        cartProducts.length > 0
      "
    >
      <v-card v-bind="themeData.subTotalBlock.data.attr">
        <v-list
          v-bind="themeData.subTotalBlock.data.nodes.listItemBlock.data.attr"
        >
          <v-list-item-group>
            <v-list-item class="d-flex">
              <div>{{ themeData.subTotalBlock.data.textSubTotal }}</div>
              <v-spacer />
              <div>
                <b>€{{ formatPrice($store.getters["cart/subTotal"]) }}</b>
              </div>
            </v-list-item>
          </v-list-item-group>
          <!-- <v-list-item-group>
            <v-list-item class="d-flex">
              <div>Delivery</div>
              <v-spacer />
              <div>
                <b>€{{ cart.deliveryCost }}</b>
              </div>
            </v-list-item>
          </v-list-item-group> -->

          <v-list-item-group
            v-bind="themeData.subTotalBlock.data.nodes.totalBlock.data.attr"
          >
            <v-list-item class="d-flex">
              <div>{{ themeData.subTotalBlock.data.textTotal }}</div>
              <v-spacer />
              <div>
                <b>€{{ formatPrice($store.getters["cart/grandTotal"]) }}</b>
              </div>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>

      <v-card
        v-if="themeData.orderDataBlock && themeData.orderDataBlock.data.active"
        v-bind="themeData.orderDataBlock.data.attr"
      >
        <v-card-text>
          <v-textarea
            v-if="
              themeData.orderDataBlock.data.nodes.orderRemarkText &&
              themeData.orderDataBlock.data.nodes.orderRemarkText.data.active
            "
            rows="2"
            outlined
            solo
            auto-grow
            v-model="form.order_remark"
            v-bind="
              themeData.orderDataBlock.data.nodes.orderRemarkText.data.attr
            "
          />
        </v-card-text>
      </v-card>
    </template>
    <!-- SUB TOTAL BLOCK -->

    <PButton
      v-if="
        themeData.moreItemButton &&
        themeData.moreItemButton.data &&
        themeData.moreItemButton.data.active
      "
      v-model="themeData.moreItemButton.data"
      @click="$router.push('menu')"
    />

    <template
      v-if="
        themeData.orderNowButton &&
        themeData.orderNowButton.data &&
        themeData.orderNowButton.data.active &&
        cartProducts.length > 0 &&
        $store.getters['cart/tableId']
      "
    >
      <div
        :style="
          pick(themeData.orderNowButton.data.attr.style, [
            'margin',
            'padding',
            'color',
          ])
        "
      >
        <v-checkbox
          v-model="agree"
          v-bind="themeData.orderNowButton.data.nodes.agreement.data.attr"
        >
          <template v-slot:label>
            <label
              :style="{
                fontSize:
                  themeData.orderNowButton.data.nodes.agreement.data.attr.style
                    .fontSize,
                color:
                  themeData.orderNowButton.data.nodes.agreement.data.attr.style
                    .color,
              }"
              >{{
                themeData.orderNowButton.data.nodes.agreement.data.text
              }}</label
            >
          </template>
        </v-checkbox>
      </div>
      <div v-if="themeData.orderNowButton && themeData.orderNowButton.data">
        <PButton
          v-model="themeData.orderNowButton.data"
          @click="makeOrder"
          v-if="agree"
          :loading="isOrderLoading && !dialogSuccess"
        />

        <PButton
          v-model="themeData.orderNowButton.data.nodes.disabledBtn.data"
          v-else
          :loading="isOrderLoading"
          disabled
        />
      </div>
    </template>

    <OrderCompleteDialog :themeData="themeData" v-model="orderCompleted" />
    <SelectPaymentDialog :themeData="themeData" v-model="dialogSuccess" />
  </PMain>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
import PButton from "../components/_render/PButton.vue";
import PMain from "../components/PMain.vue";
import comminMixin from "../mixins/common";
import OrderCompleteDialog from "./_Cart/OrderCompleteDialog.vue";
import SelectPaymentDialog from "./_Cart/SelectPaymentDialog.vue";

export default {
  components: {
    PButton,
    PMain,
    OrderCompleteDialog,
    SelectPaymentDialog,
  },
  mixins: [comminMixin],
  data: () => ({
    key: "cart",
    themeData: [],
    agree: false,
    dialogSuccess: false,
    orderCompleted: false,
    collapse: {},
    editable: false,
    productRemarkActive: {},
    form: {
      order_remark: null,
    },
  }),

  mounted() {
    if (this.orderData) {
      this.form = this.orderData;
    }
  },

  methods: {
    handleReduceQty(prod) {
      const qty = prod.quantity;
      if (qty <= 1) {
        prod.quantity = 1;
      } else {
        prod.quantity = prod.quantity - 1;
      }
    },
    handleAddQty(prod) {
      prod.quantity = prod.quantity + 1;
    },
    showOrHide(key) {
      if (this.collapse && this.collapse[key]) {
        this.$set(this.collapse, key, !this.collapse[key]);
      } else {
        this.$set(this.collapse, key, true);
      }
    },
    pick(object, key) {
      return _.pick(object, key);
    },
    deleteProduct(productObject) {
      this.$store.dispatch("cart/deleteProduct", productObject);
    },
    makeOrder() {
      if (this.generalSetting.appSetting.payment) {
        // this.dialogSuccess = true;
        this.$router.push("/payment");
      } else {
        this.$store.dispatch("cart/createOrder");
        if (!this.isOrderError) {
          this.orderCompleted = true;
        }
      }
    },
  },
  computed: {
    ...mapState({
      cartProducts: (state) => state.cart.products,
      cart: (state) => state.cart,
      isOrderLoading: (state) => state.cart.isOrderLoading,
      isOrderError: (state) => state.cart.isError,
      orderData: (state) => state.cart.data
    }),
    ...mapState({
      dbData: (state) => state.theme.data,
    }),
    ...mapGetters({
      generalSetting: "theme/general",
    }),
    dataFromDb() {
      const data = {};
      this.dbData.forEach((item) => {
        data[item.key] = item.value;
      });
      return data;
    },
  },
  watch: {
    orderData: {
      handler(newValue) {
        this.form = newValue;
      },
      deep: true,
    },
    form: {
      handler(newValue) {
        this.$store.dispatch("cart/setOrderData", newValue);
      },
      deep: true,
    },
    themeData: {
      handler(newValue) {
        if (newValue && newValue.orderConfirmationDialog) {
          if (window.location !== window.parent.location) {
            // execute this for preview only
            this.dialogSuccess =
              newValue.orderConfirmationDialog.data.showPreview;
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
/* For Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

/* Webkit browsers like Safari and Chrome */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ql-editor .ql-align-center img {
  width: auto !important;
}
</style>
