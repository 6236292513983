<template>
  <div v-bind="currentPageAttr.data.attr">
    <v-checkbox
      v-for="item in items"
      :key="item.id"
      :label="item.text"
      :value="item.value"
      dense
      hide-details
      v-model="innerValue"
      multiple
      :disabled="disableMe && !contains(item.value)"
      :color="currentPageAttr.data.attr.style.color"
      :background-color="currentPageAttr.data.attr.style.backgroundColor"
    />
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
    value: Array,
    max: Number,
    currentPageAttr: Object,
  },
  data() {
    return {
      innerValue: this.value,
      disable: false,
    };
  },
  computed: {
    disableMe() {
      if (this.innerValue && this.innerValue.length >= this.max) {
        return true;
      }
      return false;
    },
  },
  methods: {
    contains(item) {
      const selectedItems =
        typeof this.innerValue === "string"
          ? [this.innerValue]
          : this.innerValue;
      return selectedItems.includes(item);
    },
  },
  watch: {
    innerValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.innerValue = newValue;
    }
  },
};
</script>
