import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
// import '../assets/css/vuetify.css';

Vue.use(Vuetify);

const opts = {};

export default new Vuetify(opts);
