import axios from "../plugins/axios";

let cancelSource = null;

async function createOrder(payload) {
  if (cancelSource) {
    cancelSource.cancel("previous request canceled");
  }

  cancelSource = axios.CancelToken.source();
  const res = await axios.post(`/qr-app/order`, payload, {
    cancelToken: cancelSource.token,
  });
  cancelSource = null;
  return res.data;
  // .then((response) => {
  //   console.log(response);
  // })
  // .catch((error) => {
  //   console.log(error.response);
  // });
}

const cartService = {
  createOrder,
};

export default cartService;
