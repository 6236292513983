<template>
  <v-card v-bind="innerValue.attr" v-if="innerValue.active">
    <v-card-text>
      <v-row>
        <v-col cols="12" v-if="innerValue.nodes.text1.data.active">
          <PRichText
            v-bind="innerValue.nodes.text1.data.attr"
            v-model="innerValue.nodes.text1.data"
          />
        </v-col>
        <v-col cols="12" v-if="innerValue.nodes.tableSelectionList.data.active">
          <TableAutoComplete
            v-model="form.tableId"
            v-bind="innerValue.nodes.tableSelectionList.data.attr"
          />
        </v-col>
        <v-col
          cols="12"
          v-if="innerValue.nodes.tableSelectionButton.data.active"
        >
          <PButton
            v-model="innerValue.nodes.tableSelectionButton.data"
            @click="confirm()"
            :disabled="!form.tableId"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import PRichText from "./PRichText.vue";
import PButton from "./PButton.vue";
import TableAutoComplete from "../_includes/TableAutoComplete.vue";

export default {
  components: {
    PRichText,
    TableAutoComplete,
    PButton,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      innerValue: this.value,
      form: {
        tableId: null,
      },
    };
  },
  watch: {
    value(newValue) {
      this.innerValue = newValue;
    },
  },
  computed: {
    selectedTableId() {
      const { tableId } = this.$route.params;
      if (tableId) {
        return tableId;
      }
      return null;
    },
  },
  methods: {
    confirm() {
      if (!window.parentIFrame) {
        this.$router.push(`/table/${this.form.tableId}`);
      } else {
        alert(
          "You are in preview mode, all action is disabled in preview mode"
        );
      }
    },
  },
};
</script>
