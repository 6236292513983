<template>
  <v-autocomplete
    solo
    outlined
    v-model="innerValue"
    hide-details
    :items="tableItems"
    clearable
    v-bind="resetProps"
    :loading="isLoading"
  />
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  components: {},
  props: {
    value: [String, Number],
  },
  data() {
    return {
      innerValue: this.value,
      form: {
        tableId: null,
      },
    };
  },
  async mounted() {
    await this.tableGetAll();
  },
  watch: {
    value(newValue) {
      this.innerValue = newValue;
    },
    innerValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("input", newValue);
      }
    },
  },
  computed: {
    ...mapState({
      allData: (state) => state.table.data,
      isLoading: (state) => state.table.isLoading,
    }),
    tableItems() {
      return this.allData.map((item) => ({
        value: item.number,
        text: item.number,
      }));
    },
    resetProps() {
      return { ...this.$attrs };
    },
  },
  methods: {
    ...mapActions({
      tableGetAll: "table/getAll",
    }),
  },
};
</script>
