<template>
  <div v-bind="currentOptionAttr.data.attr" class="optional_group">
    <component
      v-bind:is="typeComponent"
      v-model="innerValue"
      :items="optionItems"
      :max="maxItem"
      :currentPageAttr="currentOptionAttr"
      solo
      x-small
    />
  </div>
</template>

<script>
import PCheckboxGroup from "./PCheckboxGroup.vue";
import PRadioGroup from "./PRadioGroup.vue";
import PDropdown from "./PDropdown.vue";
import commonMixin from "../../mixins/common";

export default {
  mixins: [commonMixin],
  components: {
    PCheckboxGroup,
    PRadioGroup,
    PDropdown,
  },
  props: {
    currentPageAttr: Object,
    dataObject: Object,
    value: [String, Array, Object],
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  methods: {
    applyChangeStyle() {
      document.body.style.setProperty(
        "--fontSize",
        this.currentOptionAttr.data.attr.style.fontSize
      );

      document.body.style.setProperty(
        "--fontColor",
        this.currentOptionAttr.data.attr.style.color
      );
    },
  },
  mounted() {
    this.applyChangeStyle();
  },
  computed: {
    currentOptionAttr() {
      return this.currentPageAttr.productDetailBlock.data.nodes
        .productOptionalGroup.data.nodes.body;
    },
    maxItem() {
      return this.dataObject.type_limit;
    },
    optionItems() {
      return this.dataObject.products.map((item) => ({
        value: item.article_number,
        text: `${item.name} (+€${ this.formatPrice(item.price) })`,
        price: this.formatPrice(item.price),
      }));
    },
    typeComponent() {
      const { type } = this.dataObject;
      let typeComponent = "";
      if (type === "dropdown") {
        typeComponent = "PDropdown";
      } else if (type === "checkbox") {
        typeComponent = "PCheckboxGroup";
      } else {
        typeComponent = "PRadioGroup";
      }
      return typeComponent;
    },
  },
  watch: {
    innerValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.innerValue = newValue;
    },
    currentOptionAttr: {
      handler() {
        // need to refactor this
        this.applyChangeStyle();
      },
      deep: true,
    },
  },
};
</script>

<style>
.optional_group .v-expansion-panel-header {
  font-size: var(--fontSize, "12px") !important;
}

.optional_group .v-label {
  color: var(--fontColor, #000000) !important;
  font-size: var(--fontSize, 12px) !important;
}
</style>
