var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PMain',{attrs:{"dataKey":_vm.key},model:{value:(_vm.themeData),callback:function ($$v) {_vm.themeData=$$v},expression:"themeData"}},[(!_vm.$store.getters['cart/tableId'])?_c('v-alert',{attrs:{"prominent":"","type":"warning"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v(" Please confirm your table! ")]),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{on:{"click":function($event){return _vm.$router.push('/table')}}},[_vm._v("Confirm")])],1)],1)],1):_vm._e(),(
      _vm.themeData.totalOrderedItem && _vm.themeData.totalOrderedItem.data.active
    )?[_c('v-card',_vm._b({},'v-card',_vm.themeData.totalOrderedItem.data.attr,false),[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.themeData.totalOrderedItem.data.text)+" ("+_vm._s(_vm.cartProducts.length)+") "),_c('v-spacer')],1),_c('v-card-text',[_c('v-list',{style:(_vm.pick(
              _vm.themeData.totalOrderedItem.data.attr.style,
              'backgroundColor'
            ))},[_vm._l((_vm.cartProducts),function(prod,key){return _c('v-list-item-group',{key:key},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex justify-space-between",staticStyle:{"width":"55%"}},[_c('div',{on:{"click":function($event){return _vm.showOrHide(key)}}},[_vm._v(" "+_vm._s(prod.name)+" ")])]),_c('div',{staticClass:"d-flex",staticStyle:{"width":"15%"}},[(
                          _vm.themeData.totalOrderedItem.data.nodes
                            .spinnerPlusIcon.data.active
                        )?_c('PButton',{on:{"click":function($event){return _vm.handleAddQty(prod)}},model:{value:(
                          _vm.themeData.totalOrderedItem.data.nodes
                            .spinnerPlusIcon.data
                        ),callback:function ($$v) {_vm.$set(_vm.themeData.totalOrderedItem.data.nodes
                            .spinnerPlusIcon, "data", $$v)},expression:"\n                          themeData.totalOrderedItem.data.nodes\n                            .spinnerPlusIcon.data\n                        "}}):_vm._e(),_c('div',{staticStyle:{"text-align":"center","padding":"0px 5px","margin":"0"}},[_vm._v(" "+_vm._s(prod.quantity)+" ")]),(
                          _vm.themeData.totalOrderedItem.data.nodes
                            .spinnerMinusIcon.data.active
                        )?_c('PButton',{on:{"click":function($event){return _vm.handleReduceQty(prod)}},model:{value:(
                          _vm.themeData.totalOrderedItem.data.nodes
                            .spinnerMinusIcon.data
                        ),callback:function ($$v) {_vm.$set(_vm.themeData.totalOrderedItem.data.nodes
                            .spinnerMinusIcon, "data", $$v)},expression:"\n                          themeData.totalOrderedItem.data.nodes\n                            .spinnerMinusIcon.data\n                        "}}):_vm._e()],1),_c('div',{staticClass:"d-flex justify-end",staticStyle:{"width":"30%"}},[_c('div',{staticStyle:{"width":"80%","text-align":"right"}},[_c('b',[_vm._v(" €"+_vm._s(parseFloat( prod.totalPrice * prod.quantity ).toFixed(2))+" ")])]),_c('div',{staticStyle:{"width":"20%","margin-left":"5px","text-align":"right"}},[(
                            _vm.themeData.totalOrderedItem.data.nodes
                              .deleteItemIcon.data.active
                          )?_c('PButton',{on:{"click":function($event){return _vm.deleteProduct(prod.id)}},model:{value:(
                            _vm.themeData.totalOrderedItem.data.nodes
                              .deleteItemIcon.data
                          ),callback:function ($$v) {_vm.$set(_vm.themeData.totalOrderedItem.data.nodes
                              .deleteItemIcon, "data", $$v)},expression:"\n                            themeData.totalOrderedItem.data.nodes\n                              .deleteItemIcon.data\n                          "}}):_vm._e()],1)])])]),_c('v-list-item-subtitle',[(
                      prod.optionalGroups && prod.optionalGroups.length > 0
                    )?_c('div',_vm._l((prod.optionalGroups),function(option,kk){return _c('div',{key:kk,staticStyle:{"margin":"5px 0px 0px 10px"}},[_vm._v(" "+_vm._s(option.name)+" (+€"+_vm._s(_vm.formatPrice(option.price))+") ")])}),0):_vm._e()]),(
                    _vm.dataFromDb.menu.productDetailBlock.data.nodes
                      .productRemark.data.active
                  )?_c('v-list-item-subtitle',{staticClass:"pt-2"},[_c('v-textarea',{attrs:{"label":"Product Remarks","outlined":"","solo":"","auto-grow":"","rows":"1"},model:{value:(prod.remarks),callback:function ($$v) {_vm.$set(prod, "remarks", $$v)},expression:"prod.remarks"}})],1):_vm._e()],1)],1),_c('v-divider')],1)}),(_vm.cartProducts.length == 0)?_c('v-list-item-group',[_c('v-list-item',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(_vm.themeData.totalOrderedItem.data.textNoProduct)+" ")])],1):_vm._e()],2)],1)],1)]:_vm._e(),(
      _vm.themeData.subTotalBlock &&
      _vm.themeData.subTotalBlock.data &&
      _vm.themeData.subTotalBlock.data.active &&
      _vm.cartProducts.length > 0
    )?[_c('v-card',_vm._b({},'v-card',_vm.themeData.subTotalBlock.data.attr,false),[_c('v-list',_vm._b({},'v-list',_vm.themeData.subTotalBlock.data.nodes.listItemBlock.data.attr,false),[_c('v-list-item-group',[_c('v-list-item',{staticClass:"d-flex"},[_c('div',[_vm._v(_vm._s(_vm.themeData.subTotalBlock.data.textSubTotal))]),_c('v-spacer'),_c('div',[_c('b',[_vm._v("€"+_vm._s(_vm.formatPrice(_vm.$store.getters["cart/subTotal"])))])])],1)],1),_c('v-list-item-group',_vm._b({},'v-list-item-group',_vm.themeData.subTotalBlock.data.nodes.totalBlock.data.attr,false),[_c('v-list-item',{staticClass:"d-flex"},[_c('div',[_vm._v(_vm._s(_vm.themeData.subTotalBlock.data.textTotal))]),_c('v-spacer'),_c('div',[_c('b',[_vm._v("€"+_vm._s(_vm.formatPrice(_vm.$store.getters["cart/grandTotal"])))])])],1)],1)],1)],1),(_vm.themeData.orderDataBlock && _vm.themeData.orderDataBlock.data.active)?_c('v-card',_vm._b({},'v-card',_vm.themeData.orderDataBlock.data.attr,false),[_c('v-card-text',[(
            _vm.themeData.orderDataBlock.data.nodes.orderRemarkText &&
            _vm.themeData.orderDataBlock.data.nodes.orderRemarkText.data.active
          )?_c('v-textarea',_vm._b({attrs:{"rows":"2","outlined":"","solo":"","auto-grow":""},model:{value:(_vm.form.order_remark),callback:function ($$v) {_vm.$set(_vm.form, "order_remark", $$v)},expression:"form.order_remark"}},'v-textarea',
            _vm.themeData.orderDataBlock.data.nodes.orderRemarkText.data.attr
          ,false)):_vm._e()],1)],1):_vm._e()]:_vm._e(),(
      _vm.themeData.moreItemButton &&
      _vm.themeData.moreItemButton.data &&
      _vm.themeData.moreItemButton.data.active
    )?_c('PButton',{on:{"click":function($event){return _vm.$router.push('menu')}},model:{value:(_vm.themeData.moreItemButton.data),callback:function ($$v) {_vm.$set(_vm.themeData.moreItemButton, "data", $$v)},expression:"themeData.moreItemButton.data"}}):_vm._e(),(
      _vm.themeData.orderNowButton &&
      _vm.themeData.orderNowButton.data &&
      _vm.themeData.orderNowButton.data.active &&
      _vm.cartProducts.length > 0 &&
      _vm.$store.getters['cart/tableId']
    )?[_c('div',{style:(_vm.pick(_vm.themeData.orderNowButton.data.attr.style, [
          'margin',
          'padding',
          'color' ]))},[_c('v-checkbox',_vm._b({scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{style:({
              fontSize:
                _vm.themeData.orderNowButton.data.nodes.agreement.data.attr.style
                  .fontSize,
              color:
                _vm.themeData.orderNowButton.data.nodes.agreement.data.attr.style
                  .color,
            })},[_vm._v(_vm._s(_vm.themeData.orderNowButton.data.nodes.agreement.data.text))])]},proxy:true}],null,false,43006314),model:{value:(_vm.agree),callback:function ($$v) {_vm.agree=$$v},expression:"agree"}},'v-checkbox',_vm.themeData.orderNowButton.data.nodes.agreement.data.attr,false))],1),(_vm.themeData.orderNowButton && _vm.themeData.orderNowButton.data)?_c('div',[(_vm.agree)?_c('PButton',{attrs:{"loading":_vm.isOrderLoading && !_vm.dialogSuccess},on:{"click":_vm.makeOrder},model:{value:(_vm.themeData.orderNowButton.data),callback:function ($$v) {_vm.$set(_vm.themeData.orderNowButton, "data", $$v)},expression:"themeData.orderNowButton.data"}}):_c('PButton',{attrs:{"loading":_vm.isOrderLoading,"disabled":""},model:{value:(_vm.themeData.orderNowButton.data.nodes.disabledBtn.data),callback:function ($$v) {_vm.$set(_vm.themeData.orderNowButton.data.nodes.disabledBtn, "data", $$v)},expression:"themeData.orderNowButton.data.nodes.disabledBtn.data"}})],1):_vm._e()]:_vm._e(),_c('OrderCompleteDialog',{attrs:{"themeData":_vm.themeData},model:{value:(_vm.orderCompleted),callback:function ($$v) {_vm.orderCompleted=$$v},expression:"orderCompleted"}}),_c('SelectPaymentDialog',{attrs:{"themeData":_vm.themeData},model:{value:(_vm.dialogSuccess),callback:function ($$v) {_vm.dialogSuccess=$$v},expression:"dialogSuccess"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }