<template>
<div class="ql-editor">
    <div v-html="innerValue.text" v-bind="innerValue.attr"></div>
</div>
</template>

<script>
export default {
  props: {
    value: Object,
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.innerValue = newValue;
    },
  },
};
</script>

<style>
.ql-editor {
    padding:0px !important;
    margin:0px !important;
}
.ql-editor img {
    width:100% !important;
    text-align: center !important;
}
</style>
