import axios from "../plugins/axios";

let cancelSource = null;

async function getByUniqId(id) {
  if (cancelSource) {
    cancelSource.cancel("previous request canceled");
  }

  cancelSource = axios.CancelToken.source();
  const res = await axios.get(`/qr-app/session/${id}`, {
    cancelToken: cancelSource.token,
  });
  cancelSource = null;
  return res.data;
}

const sessionService = {
  getByUniqId,
};

export default sessionService;
