var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentPageAttr.productDetailBlock)?_c('v-dialog',_vm._b({model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},'v-dialog',_vm.currentPageAttr.productDetailBlock.data.attr,false),[(_vm.product && _vm.product.menu_product)?_c('v-card',{staticClass:"mb-1"},[(
        _vm.productImageData.showImage &&
        _vm.product &&
        _vm.product.menu_product &&
        _vm.product.menu_product.product &&
        _vm.product.menu_product.product.image
      )?_c('div',{style:({
        padding: _vm.productImageData.attr.style.padding,
      })},[_c('v-img',_vm._b({attrs:{"src":_vm.product.menu_product.product.image,"lazy-src":_vm.product.menu_product.product.image},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,false,4034393411)},'v-img',_vm.productImageData.attr,false))],1):_vm._e(),(_vm.closeButtonData && _vm.closeButtonData.active)?_c('PButton',{staticStyle:{"position":"absolute","top":"0","right":"0"},on:{"click":function($event){_vm.dialog = false}},model:{value:(_vm.closeButtonData),callback:function ($$v) {_vm.closeButtonData=$$v},expression:"closeButtonData"}}):_vm._e(),(_vm.productTitleData && _vm.productTitleData.active)?_c('v-card-title',[_c('div',_vm._b({},'div',_vm.productTitleData.attr,false),[_vm._v(" "+_vm._s(_vm.product.menu_product.product.name)+" ")]),_c('v-spacer'),(_vm.productPriceData && _vm.productPriceData.active)?_c('div',_vm._b({},'div',_vm.productPriceData.attr,false),[_vm._v(" €"+_vm._s(_vm.formatPrice(_vm.product.price))+" ")]):_vm._e()],1):_vm._e(),(_vm.productDescriptionData && _vm.productDescriptionData.active)?_c('v-card-subtitle',[_c('div',_vm._b({},'div',_vm.productDescriptionData.attr,false),[_c('p',{domProps:{"innerHTML":_vm._s(_vm.product.menu_product.product.description_2)}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.product.menu_product.product.description_3)}})])]):_vm._e(),_c('v-card-text',[_vm._l((_vm.optionalGroups),function(optionalGroup,i){return _c('v-expansion-panels',{key:i,staticClass:"mb-1",attrs:{"multiple":""},model:{value:(_vm.panels),callback:function ($$v) {_vm.panels=$$v},expression:"panels"}},[_vm._l((optionalGroup.optional_group),function(opt){return _c('v-expansion-panel',_vm._b({key:(i + "_" + (opt.id)),model:{value:(_vm.panels[i]),callback:function ($$v) {_vm.$set(_vm.panels, i, $$v)},expression:"panels[i]"}},'v-expansion-panel',
              _vm.currentPageAttr.productDetailBlock.data.nodes
                .productOptionalGroup.data.attr
            ,false),[_c('v-expansion-panel-header',_vm._b({},'v-expansion-panel-header',
                _vm.currentPageAttr.productDetailBlock.data.nodes
                  .productOptionalGroup.data.nodes.title.data.attr
              ,false),[_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v(" "+_vm._s(opt.name)+" "),(!opt.is_optional)?_c('small',[_vm._v("*")]):_vm._e()])])]),_c('v-expansion-panel-content',_vm._b({},'v-expansion-panel-content',
                _vm.currentPageAttr.productDetailBlock.data.nodes
                  .productOptionalGroup.data.nodes.body.data.attr
              ,false),[_c('OptionalGroup',{attrs:{"dataObject":opt,"currentPageAttr":_vm.currentPageAttr},model:{value:(_vm.productOptions[i]),callback:function ($$v) {_vm.$set(_vm.productOptions, i, $$v)},expression:"productOptions[i]"}})],1)],1)})],2)}),(
          _vm.currentPageAttr.productDetailBlock.data.nodes.productRemark &&
          _vm.currentPageAttr.productDetailBlock.data.nodes.productRemark.data
            .active
        )?_c('v-expansion-panels',{model:{value:(_vm.productRemarkPanel),callback:function ($$v) {_vm.productRemarkPanel=$$v},expression:"productRemarkPanel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',_vm._b({},'v-expansion-panel-header',
              _vm.currentPageAttr.productDetailBlock.data.nodes.productRemark.data
                .nodes.title.data.attr
            ,false),[_vm._v(_vm._s(_vm.currentPageAttr.productDetailBlock.data.nodes.productRemark.data .nodes.title.data.text))]),_c('v-expansion-panel-content',_vm._b({},'v-expansion-panel-content',
              _vm.currentPageAttr.productDetailBlock.data.nodes.productRemark.data
                .nodes.body.data.attr
            ,false),[_c('v-textarea',_vm._b({attrs:{"outlined":"","rows":"2"},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}},'v-textarea',
                _vm.currentPageAttr.productDetailBlock.data.nodes.productRemark
                  .data.nodes.body.data.attr
              ,false))],1)],1)],1):_vm._e()],2),_c('v-divider'),(_vm.orderButtonData && _vm.orderButtonData.active)?_c('v-card-actions',[(_vm.orderButtonData.fixed)?_c('div',{staticStyle:{"position":"relative","height":"50px","width":"100%"}},[_c('div',{staticStyle:{"position":"fixed","bottom":"0","left":"0","z-index":"10000","width":"100vw"}},[_c('PButton',{attrs:{"fixed":false,"disabled":!_vm.isValidated},on:{"click":_vm.handleAddToCart},model:{value:(_vm.orderButtonData),callback:function ($$v) {_vm.orderButtonData=$$v},expression:"orderButtonData"}},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.orderButtonData.text)+" "),(_vm.orderButtonData.showTotalPrice)?_c('span',[_vm._v("(€"+_vm._s(_vm.formatPrice(_vm.granTotalPrice))+")")]):_vm._e()]})],2)],1)]):_c('div',{staticStyle:{"width":"100%"}},[_c('PButton',{attrs:{"disabled":!_vm.isValidated},on:{"click":_vm.handleAddToCart},model:{value:(_vm.orderButtonData),callback:function ($$v) {_vm.orderButtonData=$$v},expression:"orderButtonData"}},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.orderButtonData.text)+" "),(_vm.orderButtonData.showTotalPrice)?_c('span',[_vm._v("(€"+_vm._s(_vm.formatPrice(_vm.granTotalPrice))+")")]):_vm._e()]})],2)],1)]):_vm._e()],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }