<template>
  <PMain v-model="themeData" :dataKey="key">
    <OrderCompleteDialog :themeData="themeData" v-model="show" />
  </PMain>
</template>

<script>
import PMain from "../components/PMain.vue";
import comminMixin from "../mixins/common";
import OrderCompleteDialog from "./_Cart/OrderCompleteDialog.vue";

export default {
  mixins: [comminMixin],
  data: () => ({
    key: "cart",
    show: true,
    themeData: {},
  }),
  components: {
    PMain,
    OrderCompleteDialog,
  },
};
</script>
