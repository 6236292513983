import moment from "moment-timezone";

const common = {
  moment() {
    return moment().tz(process.env.VUE_APP_TIMEZONE);
  },
  getNow() {
    return common.moment().format(process.env.VUE_APP_DATETIME_FORMAT);
  },
  getNowUTC() {
    return common.moment().utc().format(process.env.VUE_APP_DATETIME_FORMAT);
  },
  getTimezone() {
    return process.env.VUE_APP_TIMEZONE;
  },
};

export default common;
