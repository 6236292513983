var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PMain',{attrs:{"dataKey":_vm.key},model:{value:(_vm.themeData),callback:function ($$v) {_vm.themeData=$$v},expression:"themeData"}},[(_vm.themeData.categoryBlock)?_c('v-row',[_c('v-col',[_c('v-tabs',_vm._b({style:(Object.assign({}, _vm.themeData.categoryBlock.data.attr.style,
          {position: _vm.themeData.categoryBlock.data.fixed ? 'fixed' : 'relative',
          zIndex: _vm.themeData.categoryBlock.data.fixed ? 1 : 1})),attrs:{"height":50,"background-color":_vm.themeData.categoryBlock.data.attr.style.backgroundColor},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},'v-tabs',_vm.themeData.categoryBlock.data.attr,false),_vm._l((_vm.categories),function(category,idx){return _c('v-tab',{key:idx,attrs:{"href":'#category-' + idx,"active-class":_vm.themeData.categoryBlock.data.nodes.tabActive.data.attr.class},on:{"click":function($event){return _vm.handleClickTab(category)}}},[_vm._v(" "+_vm._s(category.name)+" ")])}),1)],1)],1):_vm._e(),(_vm.themeData.categoryBlock && _vm.themeData.categoryBlock.data.fixed)?_c('v-row',{staticStyle:{"height":"50px"}},[_c('v-col',[_c('br')])],1):_vm._e(),(_vm.isProductLoading)?_c('v-row',_vm._l((5),function(i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"card","height":"150"}})],1)}),1):_c('v-row',_vm._l((_vm.products),function(product,idx){return _c('v-col',{key:("product_" + idx),attrs:{"cols":"12"}},[(_vm.themeData.productListBlock)?_c('v-card',_vm._b({},'v-card',_vm.themeData.productListBlock.data.attr,false),[_c('v-card-text',[_c('div',{staticClass:"d-flex justify-space-between"},[(
                _vm.imageBlockData.showImage && product.menu_product.product.image
              )?_c('div',{style:(("width:" + (100 - _vm.themeData.productListBlock.data.contentWidth) + "%"))},[_c('div',{style:({
                  margin: _vm.imageBlockData.attr.style.margin,
                })},[_c('v-img',_vm._b({style:(_vm.pick(_vm.imageBlockData.attr.style, [
                      'borderRadius',
                      'padding' ])),attrs:{"src":product.menu_product.product.image,"lazy-src":product.menu_product.product.image},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',_vm.imageBlockData.attr,false))],1)]):_vm._e(),_c('div',{style:(("width:" + (_vm.imageBlockData.showImage && product.menu_product.product.image
                  ? _vm.themeData.productListBlock.data.contentWidth
                  : 100) + "%"))},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',_vm._b({},'div',
                    _vm.themeData.productListBlock.data.nodes.title.data.attr
                  ,false),[_vm._v(" "+_vm._s(product.menu_product.product.name)+" ")]),_c('v-spacer'),(_vm.getItemShopStock(product))?_c('div',[(
                      _vm.themeData.productListBlock.data.nodes.plusIcon.data
                        .active
                    )?_c('PButton',{on:{"click":function($event){return _vm.handleAddToCart(product)}},model:{value:(
                      _vm.themeData.productListBlock.data.nodes.plusIcon.data
                    ),callback:function ($$v) {_vm.$set(_vm.themeData.productListBlock.data.nodes.plusIcon, "data", $$v)},expression:"\n                      themeData.productListBlock.data.nodes.plusIcon.data\n                    "}}):_vm._e()],1):_c('div',[_c('v-img',{attrs:{"src":"/asset/out-of-stock.png","width":"48"}})],1)],1),(
                  _vm.themeData.productListBlock.data.nodes.description &&
                  _vm.themeData.productListBlock.data.nodes.description.data
                    .active
                )?_c('div',_vm._b({},'div',
                  _vm.themeData.productListBlock.data.nodes.description.data.attr
                ,false),[_vm._v(" "+_vm._s(product.menu_product.product.description_2)+" ")]):_vm._e(),_c('div',_vm._b({},'div',_vm.themeData.productListBlock.data.nodes.price.data.attr,false),[_vm._v(" € "+_vm._s(product.price)+" ")])])])])],1):_vm._e()],1)}),1),_c('ProductDetail',{attrs:{"show":_vm.productDialog,"currentPageAttr":_vm.themeData},on:{"closed":function($event){_vm.productDialog = false}},model:{value:(_vm.productId),callback:function ($$v) {_vm.productId=$$v},expression:"productId"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }