var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PMain',{attrs:{"dataKey":_vm.key},model:{value:(_vm.themeData),callback:function ($$v) {_vm.themeData=$$v},expression:"themeData"}},[(
      _vm.themeData.text1 && _vm.themeData.text1.data && _vm.themeData.text1.data.active
    )?[_c('PRichText',{model:{value:(_vm.themeData.text1.data),callback:function ($$v) {_vm.$set(_vm.themeData.text1, "data", $$v)},expression:"themeData.text1.data"}})]:_vm._e(),(
      _vm.themeData.tableConfirm &&
      _vm.themeData.tableConfirm.data &&
      _vm.themeData.tableConfirm.data.active
    )?[_c('PTableConfirm',{model:{value:(_vm.themeData.tableConfirm.data),callback:function ($$v) {_vm.$set(_vm.themeData.tableConfirm, "data", $$v)},expression:"themeData.tableConfirm.data"}})]:_vm._e(),(
      _vm.themeData.text2 && _vm.themeData.text2.data && _vm.themeData.text2.data.active
    )?[_c('PRichText',{model:{value:(_vm.themeData.text2.data),callback:function ($$v) {_vm.$set(_vm.themeData.text2, "data", $$v)},expression:"themeData.text2.data"}})]:_vm._e(),(
      _vm.themeData.tableSelection &&
      _vm.themeData.tableSelection.data &&
      _vm.themeData.tableSelection.data.active
    )?[_c('PTableSelection',{model:{value:(_vm.themeData.tableSelection.data),callback:function ($$v) {_vm.$set(_vm.themeData.tableSelection, "data", $$v)},expression:"themeData.tableSelection.data"}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }