/* eslint-disable quotes */
<template>
  <v-app id="main">
    <v-skeleton-loader
      v-if="!loaded"
      type="card-avatar, article, actions"
    ></v-skeleton-loader>
    <router-view v-else></router-view>
  </v-app>
</template>

<script>
import iframeResizerContentWindow from "iframe-resizer";
import moment from "moment";

export default {
  data() {
    return {
      loaded: true,
      timer: null,
    };
  },
  computed: {},
  async mounted() {
    this.resizeParent();
  },
  methods: {
    resizeParent() {
      if (window.parentIFrame) {
        console.log(iframeResizerContentWindow);
        window.parentIFrame.size(100);
        // iframeResizerContentWindow({
        //   autoResize: false,
        //   sizeWidth: true,
        // });
      }
    },
  },
};
</script>

<style scoped>
#main {
  background: #f8f8f8;
  color: #313232;
}
</style>

<style>
#main a {
  color: #323232;
}
#main p {
  margin-bottom: unset;
}
</style>
