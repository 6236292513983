var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.themeData.orderConfirmationDialog &&
    _vm.themeData.orderConfirmationDialog.data.active
  )?_c('v-dialog',_vm._b({attrs:{"transition":"dialog-bottom-transition","fullscreen":""},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'v-dialog',_vm.themeData.orderConfirmationDialog.data.attr,false),[_c('v-card',[_c('v-card-text',{staticClass:"ql-editor"},[_c('div',_vm._b({},'div',_vm.themeData.orderConfirmationDialog.data.attr,false),[_c('div',{domProps:{"innerHTML":_vm._s(_vm.themeData.orderConfirmationDialog.data.text)}})])]),_c('v-card-actions',[(
          _vm.themeData.orderConfirmationDialog.data.nodes.orderMoreButton.data
            .active
        )?_c('PButton',{on:{"click":function($event){return _vm.$router.push("/menu")}},model:{value:(
          _vm.themeData.orderConfirmationDialog.data.nodes.orderMoreButton.data
        ),callback:function ($$v) {_vm.$set(_vm.themeData.orderConfirmationDialog.data.nodes.orderMoreButton, "data", $$v)},expression:"\n          themeData.orderConfirmationDialog.data.nodes.orderMoreButton.data\n        "}}):_vm._e(),_c('v-spacer'),(
          _vm.themeData.orderConfirmationDialog.data.nodes.closeButton.data.active
        )?_c('PButton',{on:{"click":function($event){return _vm.$router.push("/")}},model:{value:(
          _vm.themeData.orderConfirmationDialog.data.nodes.closeButton.data
        ),callback:function ($$v) {_vm.$set(_vm.themeData.orderConfirmationDialog.data.nodes.closeButton, "data", $$v)},expression:"\n          themeData.orderConfirmationDialog.data.nodes.closeButton.data\n        "}}):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }