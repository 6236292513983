/* eslint-disable no-shadow */
import sessionService from "../service/session.service";

const state = {
  isLoading: false,
  lastQuery: null,
  data: [],
  metaData: {},
};

const actions = {
  async getByUniqId({ commit }, id) {
    commit("SET_LOADING", true);
    await sessionService.getByUniqId(id).then((response) => {
      commit("SET_DATA", response);
      commit("SET_LOADING", false);
    });
  },
};

const mutations = {
  SET_DATA(state, data) {
    state.data = data;
  },
  SET_META_DATA(state, data) {
    state.metaData = data;
  },
  SET_LOADING(state, data) {
    state.isLoading = data;
  },
  CLEAR(state) {
    state.data = null;
  },
};

const getters = {};

const session = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default session;
