/* eslint-disable no-shadow */
import paymentService from "../service/payment.service";

const state = {
  isLoading: false,
  data: [],
  isLoadingIssuer: false,
  dataIssuer: [],
};

const actions = {
  async getAll({ commit }, payload) {
    commit("SET_LOADING", true);
    await paymentService.getAll(payload).then((response) => {
      commit("SET_DATA", response);
      commit("SET_LOADING", false);
    });
  },
  async getAllIssuer({ commit }, payload) {
    commit("SET_LOADING_ISSUER", true);
    await paymentService.getAllIssuer(payload).then((response) => {
      commit("SET_DATA_ISSUER", response);
      commit("SET_LOADING_ISSUER", false);
    });
  },
};

const mutations = {
  SET_DATA(state, data) {
    state.data = data;
  },
  SET_LOADING(state, data) {
    state.isLoading = data;
  },
  SET_DATA_ISSUER(state, data) {
    state.dataIssuer = data;
  },
  SET_LOADING_ISSUER(state, data) {
    state.isLoadingIssuer = data;
  },
};

const getters = {
  issuerList(state) {
    return state.dataIssuer.issuers.map((issuer) => ({
      value: issuer.id,
      text: issuer.name,
    }));
  },
};

const payment = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default payment;
