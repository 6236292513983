import axios from "../plugins/axios";

let cancelSource = null;

async function getAll(payload) {
  if (cancelSource) {
    cancelSource.cancel("previous request canceled");
  }

  cancelSource = axios.CancelToken.source();
  const res = await axios.get(`/qr-app/product`, {
    params: payload,
    cancelToken: cancelSource.token,
  });
  cancelSource = null;
  return res.data;
}

const productService = {
  getAll,
};

export default productService;
