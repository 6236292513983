<template>
  <v-dialog
    v-model="dialog"
    v-if="currentPageAttr.productDetailBlock"
    v-bind="currentPageAttr.productDetailBlock.data.attr"
  >
    <v-card v-if="product && product.menu_product" class="mb-1">
      <div
        :style="{
          padding: productImageData.attr.style.padding,
        }"
        v-if="
          productImageData.showImage &&
          product &&
          product.menu_product &&
          product.menu_product.product &&
          product.menu_product.product.image
        "
      >
        <v-img
          v-bind="productImageData.attr"
          :src="product.menu_product.product.image"
          :lazy-src="product.menu_product.product.image"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>

      <PButton
        @click="dialog = false"
        v-if="closeButtonData && closeButtonData.active"
        v-model="closeButtonData"
        style="position: absolute; top: 0; right: 0"
      />

      <v-card-title v-if="productTitleData && productTitleData.active">
        <div v-bind="productTitleData.attr">
          {{ product.menu_product.product.name }}
        </div>
        <v-spacer />
        <div
          v-bind="productPriceData.attr"
          v-if="productPriceData && productPriceData.active"
        >
          €{{ formatPrice(product.price) }}
        </div>
      </v-card-title>
      <v-card-subtitle
        v-if="productDescriptionData && productDescriptionData.active"
      >
        <div v-bind="productDescriptionData.attr">
          <p v-html="product.menu_product.product.description_2"></p>
          <p v-html="product.menu_product.product.description_3"></p>
        </div>
      </v-card-subtitle>
      <v-card-text>
        <v-expansion-panels
          v-for="(optionalGroup, i) in optionalGroups"
          :key="i"
          class="mb-1"
          multiple
          v-model="panels"
        >
          <template>
            <v-expansion-panel
              v-for="opt in optionalGroup.optional_group"
              :key="`${i}_${opt.id}`"
              v-model="panels[i]"
              v-bind="
                currentPageAttr.productDetailBlock.data.nodes
                  .productOptionalGroup.data.attr
              "
            >
              <v-expansion-panel-header
                v-bind="
                  currentPageAttr.productDetailBlock.data.nodes
                    .productOptionalGroup.data.nodes.title.data.attr
                "
              >
                <div class="d-flex">
                  <div>
                    {{ opt.name }}
                    <small v-if="!opt.is_optional">*</small>
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                v-bind="
                  currentPageAttr.productDetailBlock.data.nodes
                    .productOptionalGroup.data.nodes.body.data.attr
                "
              >
                <OptionalGroup
                  :dataObject="opt"
                  :currentPageAttr="currentPageAttr"
                  v-model="productOptions[i]"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
        </v-expansion-panels>

        <v-expansion-panels
          v-model="productRemarkPanel"
          v-if="
            currentPageAttr.productDetailBlock.data.nodes.productRemark &&
            currentPageAttr.productDetailBlock.data.nodes.productRemark.data
              .active
          "
        >
          <v-expansion-panel>
            <v-expansion-panel-header
              v-bind="
                currentPageAttr.productDetailBlock.data.nodes.productRemark.data
                  .nodes.title.data.attr
              "
              >{{
                currentPageAttr.productDetailBlock.data.nodes.productRemark.data
                  .nodes.title.data.text
              }}</v-expansion-panel-header
            >
            <v-expansion-panel-content
              v-bind="
                currentPageAttr.productDetailBlock.data.nodes.productRemark.data
                  .nodes.body.data.attr
              "
            >
              <v-textarea
                outlined
                rows="2"
                v-model="remarks"
                v-bind="
                  currentPageAttr.productDetailBlock.data.nodes.productRemark
                    .data.nodes.body.data.attr
                "
              ></v-textarea>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions v-if="orderButtonData && orderButtonData.active">
        <div
          v-if="orderButtonData.fixed"
          style="position: relative; height: 50px; width: 100%"
        >
          <div
            style="
              position: fixed;
              bottom: 0;
              left: 0;
              z-index: 10000;
              width: 100vw;
            "
          >
            <PButton
              @click="handleAddToCart"
              v-model="orderButtonData"
              :fixed="false"
              :disabled="!isValidated"
            >
              <slot>
                {{ orderButtonData.text }}
                <span v-if="orderButtonData.showTotalPrice"
                  >(€{{ formatPrice(granTotalPrice) }})</span
                >
              </slot>
            </PButton>
          </div>
        </div>

        <div v-else style="width: 100%">
          <PButton
            @click="handleAddToCart"
            v-model="orderButtonData"
            :disabled="!isValidated"
          >
            <slot>
              {{ orderButtonData.text }}
              <span v-if="orderButtonData.showTotalPrice"
                >(€{{ formatPrice(granTotalPrice) }})</span
              >
            </slot>
          </PButton>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import OptionalGroup from "./_ProductDetail/OptionalGroup.vue";
import PButton from "../components/_render/PButton.vue";
import ProductJson from "../dataTypes/product";
import OrderJson from "../dataTypes/order";
import commonMixin from "../mixins/common";

export default {
  mixins: [commonMixin],
  components: {
    OptionalGroup,
    PButton,
  },
  props: {
    currentPageAttr: Object,
    value: Object,
    show: Boolean,
  },
  data() {
    return {
      product: this.value,
      dialog: this.show,
      productOptions: {},
      remarks: "",
      panels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], //unfold all by default
      productRemarkPanel: 0, //unfold by default,
      validationRule: {},
    };
  },
  methods: {
    /**
     * @param keys string|array
     * @return number
     */
    sumPriceByKey(keys) {
      let total = 0;
      const keysArray = typeof keys === "string" ? [keys] : keys;
      this.optionalPrices.forEach((product) => {
        if (keysArray.includes(product.article_number)) {
          total += parseFloat(product.price);
        }
      });
      return total;
    },
    /**
     * Get product json based on article_number (product key)
     * @param string|array
     * @return array of json
     */
    getProductJson(keys) {
      const result = [];
      const keysArray = typeof keys === "string" ? [keys] : keys;
      this.optionalPrices.forEach((product) => {
        if (keysArray.includes(product.article_number)) {
          result.push(product);
        }
      });
      return result;
    },

    handleAddToCart() {
      this.$store.dispatch("cart/addProduct", this.currentProduct);
      this.dialog = false;
    },
  },
  computed: {
    isValidated() {
      const validItems = [];
      Object.entries(this.productOptions).forEach((productOption) => {
        const key = parseInt(productOption[0]);
        console.log(key, this.requiredOptionalGroups, this.productOptions);
        const value = productOption[1];
        if (
          this.requiredOptionalGroups.includes(key) &&
          value &&
          value.length > 0
        ) {
          validItems.push(true);
        }
      });
      return validItems.length === this.requiredOptionalGroups.length;
    },

    /**
     * get required optional group as array list
     * return @array
     */
    requiredOptionalGroups() {
      const requiredOptionalGroups = this.optionalGroups
        .map((optionalGroup, idx) => {
          return {
            ...optionalGroup,
            idx,
          };
        })
        .filter((optionalGroup) => {
          return (
            !optionalGroup.optional_group[0].is_optional &&
            this.product.menu_product.menu_id == optionalGroup.menu_id
          );
        })
        .map((optionalGroup) => {
          return optionalGroup.idx;
        });
      return requiredOptionalGroups;
    },
    productImageData() {
      return this.currentPageAttr.productDetailBlock.data.nodes.productImage
        .data;
    },
    closeButtonData() {
      return this.currentPageAttr.productDetailBlock.data.nodes.closeButton
        .data;
    },
    productTitleData() {
      return this.currentPageAttr.productDetailBlock.data.nodes.productTitle
        .data;
    },
    productDescriptionData() {
      return this.currentPageAttr.productDetailBlock.data.nodes
        .productDescription.data;
    },
    productPriceData() {
      return this.currentPageAttr.productDetailBlock.data.nodes.productPrice
        .data;
    },
    orderButtonData() {
      return this.currentPageAttr.productDetailBlock.data.nodes.orderButton
        .data;
    },
    optionalGroups() {
      return this.product.menu_product.optional_groups.filter((og) => {
        return og.menu_id === this.product.menu_product.menu_id;
      });
    },
    totalOptionPrice() {
      let totalPrice = 0;
      Object.entries(this.productOptions).forEach((p) => {
        const articleNumber = p[1];
        if (articleNumber) {
          totalPrice += this.sumPriceByKey(p[1]);
        }
      });
      return totalPrice;
    },

    selectedProductJson() {
      const totalPrice = [];
      totalPrice.push(this.value.menu_product.product);
      Object.entries(this.productOptions).forEach((p) => {
        const articleNumber = p[1];
        if (articleNumber) {
          const products = this.getProductJson(p[1]);
          if (_.isArray(products)) {
            products.forEach((product) => totalPrice.push(product));
          } else {
            totalPrice.push(products);
          }
        }
      });
      return totalPrice;
    },

    currentProduct() {
      const result = {
        ...new ProductJson({
          ...this.value.menu_product.product,
          price: this.product.price, // replace price from menu based, NOT base product price
        }),
        optionalGroups: [],
        totalPrice: this.granTotalPrice,
        remarks: this.remarks,
      };
      Object.entries(this.productOptions).forEach((p) => {
        const articleNumber = p[1];
        if (articleNumber) {
          const products = this.getProductJson(articleNumber);
          if (_.isArray(products)) {
            products.forEach((product) =>
              result.optionalGroups.push(new ProductJson(product))
            );
          } else {
            result.optionalGroups.push(new ProductJson(products));
          }
        }
      });
      return result;
    },

    granTotalPrice() {
      return parseFloat(this.product.price) + this.totalOptionPrice;
    },
    optionalPrices() {
      const prices = [];
      this.optionalGroups.forEach((item) => {
        item.optional_group.forEach((itemGroup) => {
          itemGroup.products.forEach((product) => {
            prices.push({
              ...product,
            });
          });
        });
      });
      // return prices;
      // to be honest, need to findout in backend side, to prevent duplication
      // this way is just fast workaround in frontend, and need to be fixed in backend
      return _.uniqBy(prices, function (e) {
        return e.article_number;
      });
    },

    productJson() {
      return new OrderJson({}, this.selectedProductJson, {});
    },

    testProductJson() {
      return false;
    },
  },
  watch: {
    value(newValue) {
      this.product = newValue;
    },
    product(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("input", newValue);
      }
    },
    show(newValue) {
      this.dialog = newValue;
    },
    dialog(newValue) {
      if (!newValue) {
        // Clear selected optional groups
        this.remarks = "";
        this.productOptions = {};
        this.productRemarkPanel = 0; //unfold by default
        this.panels = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]; // auto unfold all panels
        this.$emit("closed");
      }
    },
  },
};
</script>
