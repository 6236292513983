<template>
  <v-dialog v-model="innerValue" :fullscreen="true">
    <v-card id="select-payment">
      <v-card-title>Select Payment</v-card-title>
      <v-card-text>
        <v-radio-group v-model="form.code" row>
          <v-radio value="cash">
            <template v-slot:label>
              <v-icon x-large>mdi-cash</v-icon>
            </template>
          </v-radio>
          <v-radio value="ideal">
            <template v-slot:label>
              <img src="/asset/payment/ideal.svg" width="40" height="30" />
            </template>
          </v-radio>
          <v-radio value="paypal" disabled>
            <template v-slot:label>
              <img src="/asset/payment/paypal.svg" width="40" height="25" />
            </template>
          </v-radio>
          <v-radio value="creditcard" disabled>
            <template v-slot:label>
              <img src="/asset/payment/mastercard.svg" width="40" height="30" />
            </template>
          </v-radio>
        </v-radio-group>
        <div v-if="form.code == 'ideal'">
          <v-select
            v-model="form.options.issuer"
            label="Select Issuer"
            :items="issuerList"
            :loading="isLoadingIssuer"
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          block
          @click="makeOrder"
          :disabled="!isValid"
          :loading="isOrderLoading"
          >PAY NOW</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      themeData: {},
      radios: null,
      innerValue: this.value,
      isValid: false,
      form: {
        code: "",
        options: {
          issuer: "",
          redirectUrl: `${window.location.origin}/order-completed`,
        },
      },
    };
  },
  components: {},
  mounted() {
    this.getAllIssuer();
  },
  methods: {
    ...mapActions({
      getAllIssuer: "payment/getAllIssuer",
      setPaymentMethod: "cart/setPaymentMethod",
    }),

    makeOrder() {
      this.$store
        .dispatch("cart/createOrder")
        .then((response) => {
          if (
            response.payment_order &&
            response.payment_order.data.checkoutUrl
          ) {
            document.location.href = response.payment_order.data.checkoutUrl;
          } else {
            this.innerValue = false;
          }
        });
    },
  },
  computed: {
    ...mapState({
      isLoadingIssuer: (state) => state.payment.isLoadingIssuer,
      isOrderLoading: (state) => state.cart.isOrderLoading,
    }),
    ...mapGetters({
      issuerList: "payment/issuerList",
      orderPayload: "cart/getOrderPayload",
    }),
  },
  watch: {
    form: {
      handler(newValue) {
        const paymentOptions = {
          ...this.form,
          options: newValue.code === "ideal" ? newValue.options : {},
        };
        this.setPaymentMethod(paymentOptions);
        if (paymentOptions.code) {
          this.isValid = true;
        } else {
          this.isValid = false;
        }
      },
      deep: true,
    },
    innerValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.innerValue = newValue;
    },
  },
};
</script>

<style>
#select-payment .v-input--selection-controls__input {
  visibility: hidden;
  margin: 0 !important;
  padding: 0;
  width: 0 !important;
}

#select-payment .v-item--active {
  border: 2px solid #e2a658;
  border-radius: 5px;
  margin: 0px;
  padding: 0px;
}

#select-payment .v-radio {
  padding: 0px !important;
  margin: 2px !important;
}

#select-payment .v-radio--is-disabled {
  opacity: 0.5;
}
</style>
