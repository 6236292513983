<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="innerValue"
    v-if="
      themeData.orderConfirmationDialog &&
      themeData.orderConfirmationDialog.data.active
    "
    v-bind="themeData.orderConfirmationDialog.data.attr"
    fullscreen
  >
    <v-card>
      <v-card-text class="ql-editor">
        <div v-bind="themeData.orderConfirmationDialog.data.attr">
          <div v-html="themeData.orderConfirmationDialog.data.text"></div>
        </div>
      </v-card-text>
      <v-card-actions>
        <PButton
          v-if="
            themeData.orderConfirmationDialog.data.nodes.orderMoreButton.data
              .active
          "
          v-model="
            themeData.orderConfirmationDialog.data.nodes.orderMoreButton.data
          "
          @click="$router.push(`/menu`)"
        />
        <v-spacer />
        <PButton
          v-if="
            themeData.orderConfirmationDialog.data.nodes.closeButton.data.active
          "
          v-model="
            themeData.orderConfirmationDialog.data.nodes.closeButton.data
          "
          @click="$router.push(`/`)"
        />
      </v-card-actions>
    </v-card>  
  </v-dialog>
</template>

<script>
import PButton from "../../components/_render/PButton.vue";

export default {
  components: {
    PButton,
  },
  props: {
    themeData: [Array, Object],
    value: Boolean,
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  watch: {
    innerValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.innerValue = newValue;
    },
  },
};
</script>
