/* eslint-disable no-shadow */
import commonHelper from "../helpers/common";
import cartService from "../service/cart.service";
import OrderJson from "../dataTypes/order";

const state = {
  isLoading: false,
  data: {
    order_remark: "",
  },
  products: [],
  deliveryCost: 0,
  tableId: "",
  shopId: process.env.VUE_APP_SHOP_ID,
  remarks: "",
  paymentMethod: "cash",
  paymentMethodOptions: {},
  isOrderLoading: false,
  isError: false,
};

const actions = {
  setOrderData({ commit }, payload) {
    commit("SET_DATA", payload);
  },

  async createOrder({ commit, getters }) {
    const bodyPayload = {
      ...getters.getOrderPayload,
    };
    commit("SET_ORDER_LOADING", true);

    if (this.state.session.data && this.state.session.data.id) {
      bodyPayload.order_session = {
        id: this.state.session.data.id,
        type: "qrApp",
      };
    }
    try {
      const result = await cartService.createOrder(bodyPayload);
      commit("CLEAR_CART");
      commit("SET_ORDER_LOADING", false);
      commit("SET_ERROR", false);
      commit("session/CLEAR", null, { root: true });
      return result;
    } catch (e) {
      commit("SET_ERROR", true);
      commit("SET_ORDER_LOADING", false);
    }
  },

  setTable({ commit }, tableId) {
    commit("SET_TABLE", tableId);
  },

  create({ commit }, payload) {
    commit("PUSH_DATA", payload);
  },

  addProduct({ commit }, payload) {
    commit("ADD_PRODUCT", payload);
  },

  deleteProduct({ commit }, payload) {
    commit("DELETE_PRODUCT", payload);
  },

  setPaymentMethod({ commit }, payload) {
    commit("SET_PAYMENT_METHOD", payload);
  },
};

const mutations = {
  SET_PAYMENT_METHOD(state, payload) {
    state.paymentMethod = payload.code;
    state.paymentMethodOptions = payload.options;
  },
  SET_TABLE(state, tableId) {
    state.tableId = tableId;
  },

  SET_DATA(state, data) {
    state.data = data;
  },

  SET_ERROR(state, data) {
    state.isError = data;
  },

  PUSH_DATA(state, data) {
    state.data = [...state.data, data];
  },

  ADD_PRODUCT(state, data) {
    state.products = [...state.products, data];
  },

  DELETE_PRODUCT(state, payload) {
    const i = state.products.map((item) => item.id).indexOf(payload);
    state.products.splice(i, 1);
  },

  SET_ORDER_LOADING(state, payload) {
    state.isOrderLoading = payload;
  },

  CLEAR_CART(state) {
    state.data = {};
    state.products = [];
    state.tableId = "";
    state.paymentMethod = "";
    state.paymentMethodOptions = {};
  },
};

const getters = {
  tableId(state) {
    return state.tableId;
  },
  subTotal(state) {
    let total = 0;
    state.products.forEach((product) => {
      total += product.totalPrice * product.quantity;
    });
    return parseFloat(total).toFixed(2);
  },
  grandTotal(state, getters) {
    const total = getters.subTotal + state.deliveryCost;
    return parseFloat(total).toFixed(2);
  },
  getOrderPayload(state) {
    const allProducts = [...state.products];
    const productList = [];
    allProducts.forEach((product) => {
      productList.push({
        ...product,
      });
      product.optionalGroups.forEach((optionalGroup) => {
        productList.push({
          ...optionalGroup,
          quantity: product.quantity, //replace quantity with parent
        });
      });
    });

    const orderData = {
      ...state.data,
      table: state.tableId,
      shop_id: state.shopId,
      order_datetime: commonHelper.getNowUTC(),
      requested_time: commonHelper.getNowUTC(),
      payment_options: state.paymentMethodOptions,
      payment_method_id: state.paymentMethod,
    };

    return new OrderJson(orderData, productList, {
      name: `QR APP #${state.tableId}`,
    });
  },
};

const cart = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default cart;
