<template>
  <PMain v-model="themeData" :dataKey="key">
    <v-row>
      <v-col v-if="sessionData && sessionData.data && !isLoading">
        <v-card :loading="isLoading">
          <v-alert type="warning" v-if="willExpiresIn == null">
            Loading ...
          </v-alert>

          <v-alert type="error" v-else-if="willExpiresIn !== null && isExpired">
            Your session is expired!
          </v-alert>
          <v-alert type="info" v-else>
            Your will be redirected to {{ nextUrl }}
            <div>
              <small
                >Your session will be expired in :
                {{ willExpiresIn }} seconds</small
              >
            </div>
          </v-alert>
        </v-card>
      </v-col>
      <v-col v-else>
        <v-skeleton-loader type="card"> </v-skeleton-loader>
      </v-col>
    </v-row>
  </PMain>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import PMain from "../components/PMain.vue";
import comminMixin from "../mixins/common";

export default {
  mixins: [comminMixin],
  data: () => ({
    key: "redirect",
    themeData: [],
    agree: false,
    dialogSuccess: false,
    collapse: {},
    editable: false,
    productRemarkActive: {},
    orderData: {
      order_remark: null,
    },

    willExpiresIn: null,
  }),
  components: {
    PMain,
  },
  async mounted() {
    console.log(this.$route);
    await this.loadSession();
    window.setInterval(() => {
      this.calculate();
    }, 1000);
  },
  methods: {
    ...mapActions({
      getSession: "session/getByUniqId",
    }),

    async loadSession() {
      await this.getSession(this.sessionId);
    },
    calculate() {
      const expireTime = moment(this.sessionData.data.expiredTime).utc();
      const currentTime = moment().utc();
      this.willExpiresIn = expireTime.diff(currentTime, "seconds");
    },
  },
  watch: {
    async sessionId() {
      await this.loadSession();
    },
    isExpired() {
      if (!this.isExpired) {
        window.setTimeout(() => this.$router.push(this.nextUrl), 0);
      }
    },
  },
  computed: {
    sessionId() {
      let uniqId = null;
      if (this.$route.query && this.$route.query["sessionId"]) {
        uniqId = this.$route.query["sessionId"];
      }
      if (!uniqId && this.$route.params && this.$route.params["sessionId"]) {
        uniqId = this.$route.params["sessionId"];
      }
      return uniqId;
    },
    tableId() {
      let tableId = this.sessionData.table_id;
      if (this.$route.query && this.$route.query['table_number']) {
        tableId = this.$route.query['table_number'];
      }
      return tableId;
    },
    ...mapState({
      sessionData: (state) => state.session.data,
      isLoading: (state) => state.session.isLoading,
    }),
    isExpired() {
      if (this.willExpiresIn > 0) {
        return false;
      }
      return true;
    },
    nextUrl() {
      return `/table/${this.tableId}`;
    },
  },
};
</script>
