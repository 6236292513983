import Vue from "vue";
import VueRouter from "vue-router";
import WelcomeText from "../pages/WelcomeText.vue";
import Menu from "../pages/Menu.vue";
import TableSelector from "../pages/TableSelector.vue";
import CartNew from "../pages/CartNew.vue";
import TableConfirm from "../pages/TableConfirm.vue";
import Redirect from "../pages/Redirect.vue";
import OrderCompleted from "../pages/OrderCompleted.vue";
import Payment from "../pages/Payment.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Welcome",
    component: WelcomeText,
  },
  {
    path: "/table",
    name: "TableSelector",
    component: TableSelector,
  },
  {
    path: "/table/:tableId",
    name: "TableConfirm",
    component: TableConfirm,
  },
  {
    path: "/menu",
    name: "Menu",
    component: Menu,
  },
  {
    path: "/menu/:category_id",
    name: "MenuCategory",
    component: Menu,
  },
  {
    path: "/cart",
    name: "cart",
    component: CartNew,
  },
  {
    path: "/payment",
    name: "payment",
    component: Payment,
  },
  {
    path: "/redirect",
    name: "redirect",
    component: Redirect,
  },
  {
    path: "/session/:sessionId",
    name: "redirect",
    component: Redirect,
  },
  {
    path: "/order-completed",
    name: "OrderCompleted",
    component: OrderCompleted,
  },
];
const router = new VueRouter({
  routes,
  mode: "history",
});
export default router;
