import CustomerJson from "./customer";
import ProductJson from "./product";

const getTotalPrice = function (products) {
  let total = 0;
  products.forEach((product) => {
    total += parseFloat(product.price) * parseInt(product.quantity);
  });
  return total;
};

export default function (order = {}, products = [], customer = {}) {
  this.order_status_id = process.env.VUE_APP_ORDER_STATUS_ID;
  this.order_source_id = process.env.VUE_APP_ORDER_SOURCE_ID;
  this.order_type_id = process.env.VUE_APP_ORDER_TYPE_ID;
  this.shop_id = order.shop_id ? order.shop_id : null;
  this.courier_type_id = process.env.VUE_APP_COURIER_TYPE_ID;
  this.cancellation_id = null;
  this.cancellation_comment = null;
  this.delivery_cost = "0";
  this.delivery_vat_id = null;
  this.delivery_vat_amount = 0;
  this.delivery_remarks = null;
  this.payment_method_id = order.payment_method_id ? order.payment_method_id : "cash";
  this.payment_session_id = null;
  this.is_asap = 0;
  this.requested_time = order.requested_time;
  this.order_datetime = order.order_datetime;
  this.total_discount = 0;
  this.table = order.table;

  this.extra_costs = 0;
  this.total_vat_amount = 0;
  this.extra_cost_vat_id = null;
  this.extra_cost_vat_amount = 0;
  this.is_paid = order.is_paid ? order.is_paid : 0;
  this.order_remark = order.order_remark ? order.order_remark : "";
  this.number_of_guests = 0;
  this.course = null;
  this.fulfillment_issues = null;
  this.distance = null;
  this.invoice_id = null;
  this.courier_left_at = null;
  this.courier_back_at = null;
  this.kitchen_id = null;
  this.courier_id = null;
  this.is_discount_percentage = null;

  this.discounts = [];
  this.piggy_stage = 0;
  this.piggy_physical_reward = 0;
  this.daily_order_number = null;
  this.is_pos_sync = 0;
  this.customer = new CustomerJson(customer);
  this.products = products.map((product) => new ProductJson(product));
  this.total_price = getTotalPrice(this.products);
  this.pays_with = this.total_price;
  this.payment_options = order.payment_options;
}
