/* eslint-disable no-shadow */
import categoryService from "../service/category.service";

const state = {
  isLoading: false,
  lastQuery: null,
  data: [],
  metaData: {},
};

const actions = {
  async getAll({
    commit,
    state
  }, payload) {
    const newQuery = JSON.stringify(payload);
    if (state.lastQuery !== newQuery) {
      commit("SET_LOADING", true);
      commit('SET_LAST_QUERY', newQuery);
      await categoryService.getAll(payload).then((response) => {
        commit("SET_DATA", response.data);
        commit("SET_META_DATA", response.meta);
        commit("SET_LOADING", false);
      });
    }
  },
};

const mutations = {
  SET_DATA(state, data) {
    state.data = data;
  },
  SET_META_DATA(state, data) {
    state.metaData = data;
  },
  SET_LOADING(state, data) {
    state.isLoading = data;
  },
  SET_LAST_QUERY(state, data) {
    state.lastQuery = data;
  }
};

const getters = {};

const category = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default category;
