/* eslint-disable */
/* eslint-disable no-param-reassign */
<template>
  <PMain v-model="themeData" :dataKey="key">
    <!-- SUB TOTAL BLOCK -->
    <v-card
      v-if="themeData.paymentBlock && themeData.paymentBlock.data.active"
      id="select-payment"
      v-bind="themeData.paymentBlock.data.attr"
    >
      <v-card-text>
        <v-row>
          <v-col>
            <v-radio-group v-model="form.code" row>
              <v-radio
                :value="paymentMethod.code"
                v-for="(paymentMethod, i) in selectedItems"
                :key="i"
              >
                <template v-slot:label>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <img
                        :src="`/asset/payment/${paymentMethod.code}.svg`"
                        width="40"
                        height="30"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <span>{{ paymentMethod.text }}</span>
                  </v-tooltip>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row v-if="form.code == 'ideal'">
          <v-col>
            <v-select
              v-model="form.options[form.code].issuer"
              label="Select Bank/ Issuer"
              :items="issuerList"
              :loading="isLoadingIssuer"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div v-if="themeData.payNowButton && themeData.payNowButton.data.active">
      <PButton
        v-model="themeData.payNowButton.data"
        :disabled="!isValid"
        @click="makeOrder"
        :loading="isOrderLoading"
      />
    </div>

    <template
      v-if="
        themeData.subTotalBlock &&
        themeData.subTotalBlock.data &&
        themeData.subTotalBlock.data.active &&
        cartProducts.length > 0
      "
    >
      <v-card v-bind="themeData.subTotalBlock.data.attr">
        <v-list
          v-bind="themeData.subTotalBlock.data.nodes.listItemBlock.data.attr"
        >
          <v-list-item-group>
            <v-list-item class="d-flex">
              <div>{{ themeData.subTotalBlock.data.textSubTotal }}</div>
              <v-spacer />
              <div>
                <b>€{{ formatPrice($store.getters["cart/subTotal"]) }}</b>
              </div>
            </v-list-item>
          </v-list-item-group>

          <v-list-item-group
            v-bind="themeData.subTotalBlock.data.nodes.totalBlock.data.attr"
          >
            <v-list-item class="d-flex">
              <div>{{ themeData.subTotalBlock.data.textTotal }}</div>
              <v-spacer />
              <div>
                <b>€{{ formatPrice($store.getters["cart/grandTotal"]) }}</b>
              </div>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>

      <v-card
        v-if="themeData.orderDataBlock && themeData.orderDataBlock.data.active"
        v-bind="themeData.orderDataBlock.data.attr"
      >
        <v-card-text>
          <v-textarea
            v-if="
              themeData.orderDataBlock.data.nodes.orderRemarkText &&
              themeData.orderDataBlock.data.nodes.orderRemarkText.data.active
            "
            rows="2"
            outlined
            solo
            auto-grow
            v-model="orderData.order_remark"
            v-bind="
              themeData.orderDataBlock.data.nodes.orderRemarkText.data.attr
            "
          />
        </v-card-text>
      </v-card>
    </template>
    <!-- SUB TOTAL BLOCK -->
  </PMain>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import _ from "lodash";
import PButton from "../components/_render/PButton.vue";
import PMain from "../components/PMain.vue";
import comminMixin from "../mixins/common";

export default {
  mixins: [comminMixin],
  data: () => ({
    key: "payment",
    themeData: [],
    orderData: {
      order_remark: null,
    },
    form: {
      code: "",
      options: {
        cash: {
        },
        ideal: {
          issuer: "",
          redirectUrl: `${window.location.origin}/order-completed`,
        },
        pin: {},
        onaccount: {},
      },
    },
    isValid: false,
  }),
  components: {
    PButton,
    PMain,
  },
  mounted() {
    if (window.location === window.parent.location) {
      //if not in preview
      if (this.cartProducts.length === 0) {
        this.$router.push('/');
      }
    }

    this.fetchPaymentMethods({
      is_active: 1,
      parent_id: "",
    });
    this.getAllIssuer();
  },
  methods: {
    ...mapActions({
      fetchPaymentMethods: "paymentMethod/getAll",
      getAllIssuer: "payment/getAllIssuer",
      setPaymentMethod: "cart/setPaymentMethod",
    }),
    pick(object, key) {
      return _.pick(object, key);
    },
    makeOrder() {
      this.$store.dispatch("cart/createOrder").then((response) => {
        if (response.payment_order && response.payment_order.data.checkoutUrl) {
          // handle iDeal (need to refactor in next some requirement changes)
          document.location.href = response.payment_order.data.checkoutUrl;
        } else {
          document.location.href = `${window.location.origin}/order-completed`;
        }
      });
    },
  },
  computed: {
    ...mapState({
      cartProducts: (state) => state.cart.products,
      cart: (state) => state.cart,
      isOrderLoading: (state) => state.cart.isOrderLoading,
      isOrderError: (state) => state.cart.isError,
      paymentMethodList: (state) => state.paymentMethod.data,
      isLoadingIssuer: (state) => state.payment.isLoadingIssuer,
    }),
    ...mapGetters({
      issuerList: "payment/issuerList",
      orderPayload: "cart/getOrderPayload",
    }),
    ...mapState({
      dbData: (state) => state.theme.data,
    }),
    ...mapGetters({
      generalSetting: "theme/general",
    }),
    dataFromDb() {
      const data = {};
      this.dbData.forEach((item) => {
        data[item.key] = item.value;
      });
      return data;
    },
    selectedItems() {
      return this.themeData.paymentBlock.data.nodes.paymentList.data.items.filter(
        (item) => item
      );
    },
  },
  watch: {
    cartProducts: {
      handler(newValue) {
        if (newValue.length === 0) {
          this.$router.push("/");
        }
      },
      deep: true,
    },
    form: {
      handler(newValue) {
        const paymentOptions = {
          ...this.form,
          options: {
            ...newValue.options[newValue.code],
          },
        };
        this.setPaymentMethod(paymentOptions);

        let validCount = 0;
        let totalCount = 0;
        Object.entries(newValue.options[newValue.code]).forEach((item) => {
          if (item[1]) {
            validCount++;
          }
          totalCount++;
        });

        if (validCount === totalCount) {
          this.isValid = true;
        } else {
          this.isValid = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
#select-payment .v-input--selection-controls__input {
  visibility: hidden;
  margin: 0 !important;
  padding: 0px;
  width: 0 !important;
}

#select-payment .v-item--active {
  border: 2px solid #fc6020 !important;
  border-radius: 5px;
  margin: 0px;
  padding: 0px;
}

#select-payment .v-radio {
  padding: 6px 5px !important;
  margin: 5px !important;
  border: 1px solid #cfcfcf;
  border-radius: 10px !important;
}

#select-payment .v-radio--is-disabled {
  opacity: 0.5;
}
</style>
