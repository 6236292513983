import axios from "../plugins/axios";

async function getAll(payload) {
  const res = await axios.get("/qr-app/table", payload);
  return res.data.data;
}

const tableService = {
  getAll,
};

export default tableService;
