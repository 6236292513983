/* eslint-disable no-shadow */
import tableService from '../service/table.service';

const state = {
  isLoading: false,
  data: [],
};

const actions = {
  async getAll({
    commit
  }, payload) {
    commit('SET_LOADING', true);
    await tableService.getAll(payload).then((response) => {
      commit('SET_DATA', response);
      commit('SET_LOADING', false);
    });
  },
};

const mutations = {
  SET_DATA(state, data) {
    state.data = data;
  },
  SET_LOADING(state, data) {
    state.isLoading = data;
  },
};

const getters = {};

const table = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default table;
