export default function (customer) {
  this.name = customer.name;
  this.company_name = customer.company_name;
  this.phone_number = customer.phone_number;
  this.email = customer.email;
  this.street = null;
  this.street_extra = null;
  this.postal_code = customer.postal_code;
  this.city = null;
  this.country = null;
  this.longitude = customer.longitude;
  this.latitude = customer.latitude;
}
