/* eslint-disable */
/* eslint-disable no-param-reassign */
<template>
  <PMain v-model="themeData" :dataKey="key">
    <template v-if="generalSetting.appSetting.table">
      <PTableSelection
        v-if="themeData.tableSelection"
        v-model="themeData.tableSelection.data"
      />
    </template>
    <template v-else>
      <v-alert type="error">Please select table first!</v-alert>
    </template>
  </PMain>
</template>

<script>
import { mapGetters } from "vuex";
import PTableSelection from "../components/_render/PTableSelection.vue";
import PMain from "../components/PMain.vue";

export default {
  data: () => ({
    key: "tableSelector",
    themeData: {},
  }),
  components: {
    PTableSelection,
    PMain,
  },
  computed: {
    ...mapGetters({
      generalSetting: "theme/general",
    }),
  },
};
</script>
