import Vue from 'vue';
import axios from 'axios';
import '@/assets/css/main.css';
import '@/assets/css/quil.core.css';
import "@/assets/css/fonts.css";
import store from "./store/index";
import router from "./router/index";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";

Vue.use(require("vue-moment"));
Vue.prototype.$http = axios;
Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
  created() {},
}).$mount("#app");
