import moment from "moment";
import settingService from "../service/setting.service";

const state = {
  isLoading: false,
  data: [],
  expiredTime: null,
};

const actions = {
  async getAll({ commit }, payload) {
    commit("SET_LOADING", true);
    await settingService.getAll(payload).then((response) => {
      commit("SET_DATA", response);
      commit("SET_LOADING", false);
    });
  },

  clearAll({ commit }) {
    commit("SET_DATA", []);
  },
};

const mutations = {
  SET_LOADING(state, data) {
    state.isLoading = data;
  },
  SET_DATA(state, data) {
    state.data = data;
    const expiredValue = process.env.VUE_APP_CACHE_EXPIRATION_IN_MINUTE
      ? process.env.VUE_APP_CACHE_EXPIRATION_IN_MINUTE
      : 5;
    state.expiredTime = moment().add(expiredValue, "minute");
    console.log(`set expired theme every ${expiredValue} mins`, state.expiredTime);
  },
};

const getters = {
  general(state) {
    let found = state.data.filter((item) => item.key === "general");
    if (found.length >= 1 && found[0] && found[0].value) {
      return found[0].value;
    }
    return null;
  },
  isExpired(state) {
    const now = moment();
    const expired = moment(state.expiredTime);
    if (null === state.expiredTime || expired.diff(now) < 0) {
      return true;
    }
    return false;
  },
};

const theme = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default theme;
