import axios from "../plugins/axios";

async function getAll(payload) {
  const res = await axios.get("/payment/payment-method", {
    params: payload,
  });
  return res.data.data;
}

const paymentMethodService = {
  getAll,
};

export default paymentMethodService;
