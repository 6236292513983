/* eslint-disable no-shadow */
import paymentMethodService from "../service/paymentMethod.service";

const state = {
  isLoading: false,
  data: [],
};

const actions = {
  async getAll({ commit }, payload) {
    commit("SET_LOADING", true);
    await paymentMethodService.getAll(payload).then((response) => {
      commit("SET_DATA", response.data);
      commit("SET_LOADING", false);
    });
  },
};

const mutations = {
  SET_DATA(state, data) {
    state.data = data;
  },
  SET_LOADING(state, data) {
    state.isLoading = data;
  },
};

const getters = {};

const paymentMethod = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default paymentMethod;
