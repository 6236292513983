/* eslint-disable no-param-reassign */
<template>
  <PMain v-model="themeData" :dataKey="key">
    <template v-if="themeData.text3 && themeData.text3.data.active">
      <PRichText v-model="themeData.text3.data" />
    </template>

    <template v-if="themeData.text2 && themeData.text2.data.active">
      <PRichText v-model="themeData.text2.data" />
    </template>

    <template v-if="themeData.text1 && themeData.text1.data.active">
      <PRichText v-model="themeData.text1.data" />
    </template>

    <template v-if="themeData.button1 && themeData.button1.data.active">
      <PButton
        v-model="themeData.button1.data"
        @click="handleButton"
        :loading="isLoadingButton"
      />
    </template>
  </PMain>
</template>

<script>
import PRichText from "../components/_render/PRichText.vue";
import PButton from "../components/_render/PButton.vue";
import PMain from "../components/PMain.vue";

export default {
  data: () => ({
    key: "welcomeText",
    themeData: {},
    isLoadingButton: false,
  }),
  components: {
    PButton,
    PRichText,
    PMain,
  },
  computed: {},
  methods: {
    handleButton() {
      if (window.parentIFrame) {
        alert(
          "You are in preview mode, all action is disabled in preview mode"
        );
        return false;
      }
      this.isLoadingButton = true;
      setTimeout(() => this.$router.push("/table"), 1000);
    },
  },
};
</script>
