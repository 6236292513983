<template>
  <div v-bind="{ ...wrapper.attr, ...$attrs }">
    <v-btn
      v-if="button.attr.icon"
      v-bind="button.attr"
      @click.prevent="$emit('click', $event)"
      :disabled="disabled"
      :loading="loading"
    >
      <slot>
        <v-icon>{{ button.text }}</v-icon>
      </slot>
    </v-btn>
    <v-btn
      v-else
      v-bind="button.attr"
      @click.prevent="$emit('click', $event)"
      :disabled="disabled"
      :loading="loading"
    >
      <slot>
        {{ button.text }}
      </slot>
    </v-btn>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    value: Object,
    disabled: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.innerValue = newValue;
    },
  },
  mounted() {},
  methods: {},
  computed: {
    wrapper() {
      const margin =
        this.innerValue.attr.style && this.innerValue.attr.style.margin
          ? this.innerValue.attr.style.margin
          : false;
      return {
        attr: {
          style: {
            margin,
          },
        },
      };
    },
    button() {
      const styleNoMargin = {
        ..._.omit(this.innerValue.attr.style, ["margin"]),
      };
      return {
        ...this.innerValue,
        attr: {
          ...this.innerValue.attr,
          style: {
            ...styleNoMargin,
          },
        },
      };
    },
  },
};
</script>
