import axios from "../plugins/axios";

let cancelSource = null;
async function getAll(payload) {
  if (cancelSource) {
    cancelSource.cancel("previous request canceled");
  }
  cancelSource = axios.CancelToken.source();
  const res = await axios.get(`/qr-app/category?menu_id=${payload.menu_id}`, {
    cancelToken: cancelSource.token,
  });
  cancelSource = null;
  return res.data;
}

const categoryService = {
  getAll,
};

export default categoryService;
