var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',_vm._b({},'v-card',_vm.innerValue.attr,false),[_c('v-row',[(
        _vm.innerValue.nodes.text1 &&
        _vm.innerValue.nodes.text1.data &&
        _vm.innerValue.nodes.text1.data.active
      )?_c('v-col',{attrs:{"cols":"12"}},[_c('PRichText',{model:{value:(_vm.innerValue.nodes.text1.data),callback:function ($$v) {_vm.$set(_vm.innerValue.nodes.text1, "data", $$v)},expression:"innerValue.nodes.text1.data"}})],1):_vm._e(),(
        _vm.innerValue.nodes.tableTextConfirm &&
        _vm.innerValue.nodes.tableTextConfirm.data &&
        _vm.innerValue.nodes.tableTextConfirm.data.active
      )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',_vm._b({attrs:{"solo":"","readonly":_vm.innerValue.nodes.tableTextConfirm.data.attr.readOnly},scopedSlots:_vm._u([(
            _vm.innerValue.nodes.tableTextConfirmButton &&
            _vm.innerValue.nodes.tableTextConfirmButton.data &&
            _vm.innerValue.nodes.tableTextConfirmButton.data.active
          )?{key:"append",fn:function(){return [_c('v-fade-transition',{attrs:{"leave-absolute":""}},[_c('PButton',{on:{"click":function($event){return _vm.confirm()}},model:{value:(_vm.innerValue.nodes.tableTextConfirmButton.data),callback:function ($$v) {_vm.$set(_vm.innerValue.nodes.tableTextConfirmButton, "data", $$v)},expression:"innerValue.nodes.tableTextConfirmButton.data"}})],1)]},proxy:true}:null],null,true),model:{value:(_vm.tableId),callback:function ($$v) {_vm.tableId=$$v},expression:"tableId"}},'v-text-field',_vm.innerValue.nodes.tableTextConfirm.data.attr,false))],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }