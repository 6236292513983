import axios from "../plugins/axios";

async function getAll(payload) {
  const res = await axios.get("/qr-app/setting", payload);
  return res.data.data;
  // .then((response) => {
  //   console.log(response);
  // })
  // .catch((error) => {
  //   console.log(error.response);
  // });
}

const settingService = {
  getAll,
};

export default settingService;
