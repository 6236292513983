/* eslint-disable max-len */
<template>
  <v-card v-bind="innerValue.attr">
    <v-row>
      <v-col
        cols="12"
        v-if="
          innerValue.nodes.text1 &&
          innerValue.nodes.text1.data &&
          innerValue.nodes.text1.data.active
        "
      >
        <PRichText v-model="innerValue.nodes.text1.data" />
      </v-col>

      <v-col
        cols="12"
        v-if="
          innerValue.nodes.tableTextConfirm &&
          innerValue.nodes.tableTextConfirm.data &&
          innerValue.nodes.tableTextConfirm.data.active
        "
      >
        <v-text-field
          solo
          v-bind="innerValue.nodes.tableTextConfirm.data.attr"
          v-model="tableId"
          :readonly="innerValue.nodes.tableTextConfirm.data.attr.readOnly"
        >
          <template
            v-slot:append
            v-if="
              innerValue.nodes.tableTextConfirmButton &&
              innerValue.nodes.tableTextConfirmButton.data &&
              innerValue.nodes.tableTextConfirmButton.data.active
            "
          >
            <v-fade-transition leave-absolute>
              <PButton
                v-model="innerValue.nodes.tableTextConfirmButton.data"
                @click="confirm()"
              />
            </v-fade-transition>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import PRichText from "./PRichText.vue";
import PButton from "./PButton.vue";

export default {
  components: {
    PRichText,
    PButton,
  },
  props: {
    value: Object,
  },
  computed: {
    selectedTableId() {
      const { tableId } = this.$route.params;
      if (tableId) {
        return tableId;
      }
      return null;
    },
  },
  data() {
    const { tableId } = this.$route.params;
    return {
      innerValue: this.value,
      tableId: tableId,
    };
  },
  watch: {
    value(newValue) {
      this.innerValue = newValue;
    },
    selectedTableId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.tableId = newValue;
      }
    },
  },
  methods: {
    confirm() {
      if (!window.parentIFrame) {
        this.$store.dispatch("cart/setTable", this.tableId);
        this.$router.push(`/menu`);
      } else {
        alert(
          "You are in preview mode, all action is disabled in preview mode"
        );
      }
    },
  },
};
</script>
