import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import theme from "./theme.module";
import snackbar from "./snackbar.module";
import table from "./table.module";
import category from "./category.module";
import product from "./product.module";
import cart from "./cart.module";
import session from "./session.module";
import payment from "./payment.module";
import paymentMethod from "./paymentMethod.module";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  state: {},
  plugins: [vuexLocal.plugin],
  modules: {
    theme,
    snackbar,
    table,
    category,
    product,
    cart,
    session,
    payment,
    paymentMethod,
  },
});
