<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      v-bind="generalSetting.leftSideNav.attr"
    >
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title @click="$router.push('/')"
              >Home</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <div
          v-bind="generalSetting.leftSideNav.nodes.logo.data.attr"
          v-if="generalSetting.leftSideNav.nodes.logo.data.active"
        >
          <img v-bind="generalSetting.appLogo.attr" />
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app v-bind="value.attr">
      <v-app-bar-nav-icon
        v-if="generalSetting.leftSideNav && generalSetting.leftSideNav.active"
        @click="handleDrawer"
        :style="{ color: value.attr.style.color }"
      ></v-app-bar-nav-icon>
      <v-toolbar-title
        :style="{
          color: value.attr.style.color,
          fontSize: value.attr.style.fontSize,
        }"
        >{{ value.text }}</v-toolbar-title
      >
      <v-spacer />
      <template v-if="generalSetting.appSetting.translation">
        <v-menu offset-y :style="{ color: value.attr.style.color }" rounded>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :style="{ color: value.attr.style.color }"
              dark
              v-bind="attrs"
              v-on="on"
              text
            >
              {{ selectedLang }}
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-for="(item, index) in items" :key="index">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-if="generalSetting.appSetting.accountLogin">
        <v-btn text :style="{ color: value.attr.style.color }">Log in</v-btn>
      </template>
      <v-badge
        color="warning"
        :content="`${totalProduct}`"
        overlap
        offset-x="20"
        offset-y="25"
      >
        <v-btn
          :loading="isLoading"
          icon
          :style="{ color: value.attr.style.color }"
          @click="goToCart"
          depressed
        >
          <v-icon>mdi-cart</v-icon>
        </v-btn>
      </v-badge>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  setup() {},
  props: {
    value: Object,
  },
  data() {
    return {
      isLoading: false,
      drawer: false,
      group: null,
      innerValue: this.value,
      items: [
        {
          key: "en",
          title: "🇺🇸 en",
        },
        {
          key: "de",
          title: "🇩🇪 de",
        },
        {
          key: "nl",
          title: "🇳🇱 nl",
        },
      ],
    };
  },
  watch: {
    value(newValue) {
      this.innerValue = newValue;
    },
    innerValue(newValue) {
      this.$emit("input", newValue);
    },
  },
  methods: {
    handleDrawer() {
      this.$emit("iconClicked", true);
      this.drawer = true;
    },
    goToCart() {
      if (!this.isCurrentLocation) {
        this.isLoading = true;
        window.setTimeout(() => {
          this.$router.push("/cart");
          this.isLoading = false;
        }, 1000);
      }
    },
  },
  computed: {
    ...mapGetters({
      generalSetting: "theme/general",
    }),
    ...mapState({
      cartProducts: (state) => state.cart.products,
    }),
    totalProduct() {
      return this.cartProducts.length > 0 ? this.cartProducts.length : 0;
    },
    selectedLang() {
      let found = this.items.filter(
        (item) => item.key === this.generalSetting.appSetting.defaultLanguage
      );
      if (found.length >= 1 && found[0]) {
        return found[0].title;
      }
      return null;
    },
    isCurrentLocation() {
      return window.location.pathname === "/cart";
    },
  },
};
</script>
