/* eslint-disable */
/* eslint-disable no-param-reassign */
<template>
  <PMain v-model="themeData" :dataKey="key">
    <template
      v-if="
        themeData.text1 && themeData.text1.data && themeData.text1.data.active
      "
    >
      <PRichText v-model="themeData.text1.data" />
    </template>

    <template
      v-if="
        themeData.tableConfirm &&
        themeData.tableConfirm.data &&
        themeData.tableConfirm.data.active
      "
    >
      <PTableConfirm v-model="themeData.tableConfirm.data" />
    </template>

    <template
      v-if="
        themeData.text2 && themeData.text2.data && themeData.text2.data.active
      "
    >
      <PRichText v-model="themeData.text2.data" />
    </template>

    <template
      v-if="
        themeData.tableSelection &&
        themeData.tableSelection.data &&
        themeData.tableSelection.data.active
      "
    >
      <PTableSelection v-model="themeData.tableSelection.data" />
    </template>
  </PMain>
</template>

<script>
import PRichText from "../components/_render/PRichText.vue";
import PTableConfirm from "../components/_render/PTableConfirm.vue";
import PTableSelection from "../components/_render/PTableSelection.vue";
import PMain from "../components/PMain.vue";

export default {
  data: () => ({
    key: "tableConfirm",
    themeData: {},
  }),
  components: {
    PRichText,
    PTableConfirm,
    PTableSelection,
    PMain,
  },
  methods: {},
};
</script>
