import axios from "../plugins/axios";

async function getAll(payload) {
  const res = await axios.get("/table", payload);
  return res.data.data;
}

async function getAllIssuer(payload) {
  const res = await axios.get("/payment/issuer", payload);
  return res.data.data;
}

const tableService = {
  getAll,
  getAllIssuer,
};

export default tableService;
