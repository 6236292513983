export default function (product) {
  this.id = product.article_number ? product.article_number : product.id;
  this.name = product.name;
  this.price = product.price;
  this.quantity = product.quantity ? product.quantity : 1;
  this.remarks = product.remarks ? product.remarks : null;
  this.is_optional = null;
  this.vat_id = null; //product.vat_id;
  this.vat_amount = product.vats ? product.vats.percentage : null;
  this.is_discount_percentage = 0;
  this.discount = 0;
  this.discounted_amount = 0;
}
