var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.generalAttr && _vm.generalAttr.appBackground)?_c('v-app',_vm._b({},'v-app',Object.assign({}, _vm.generalAttr.appBackground.attr),false),[(
      _vm.currentPageAttr &&
      _vm.currentPageAttr.header &&
      _vm.currentPageAttr.header.data &&
      _vm.currentPageAttr.header.data.active
    )?_c('PHeader',{model:{value:(_vm.currentPageAttr.header.data),callback:function ($$v) {_vm.$set(_vm.currentPageAttr.header, "data", $$v)},expression:"currentPageAttr.header.data"}}):_vm._e(),_c('v-main',[_vm._t("default")],2),(
      _vm.generalAttr &&
      _vm.generalAttr.scrollTopButton &&
      _vm.generalAttr.scrollTopButton.active
    )?_c('PButton',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"}],on:{"click":_vm.toTop},model:{value:(_vm.generalAttr.scrollTopButton),callback:function ($$v) {_vm.$set(_vm.generalAttr, "scrollTopButton", $$v)},expression:"generalAttr.scrollTopButton"}}):_vm._e(),(_vm.currentPageAttr.footer && _vm.currentPageAttr.footer.data.active)?_c('v-footer',_vm._b({staticClass:"d-flex justify-space-between"},'v-footer',_vm.currentPageAttr.footer.data.attr,false),[_c('div',{staticStyle:{"width":"70%"}},[_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v(" © "+_vm._s(new Date().getFullYear())+" "+_vm._s(_vm.currentPageAttr.footer.data.text)+" ")]),_c('div',{staticStyle:{"margin-left":"20px"}},[_c('small',[_vm._v("("+_vm._s(_vm.version)+")")])])])]),_c('a',{attrs:{"href":"https://www.foodyx.nl/","target":"_blank"}},[_c('img',{style:({ height: ((_vm.currentPageAttr.footer.data.logoHeight) + "px") }),attrs:{"src":("/asset/Foodyx_Logo_" + (_vm.currentPageAttr.footer.data.logoColor) + "_60x172.png")}})])]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }