/* eslint-disable no-param-reassign */
<template>
  <v-app
    v-bind="{ ...generalAttr.appBackground.attr }"
    v-if="generalAttr && generalAttr.appBackground"
  >
    <PHeader
      v-if="
        currentPageAttr &&
        currentPageAttr.header &&
        currentPageAttr.header.data &&
        currentPageAttr.header.data.active
      "
      v-model="currentPageAttr.header.data"
    />
    <v-main>
      <slot></slot>
    </v-main>
    <PButton
      v-if="
        generalAttr &&
        generalAttr.scrollTopButton &&
        generalAttr.scrollTopButton.active
      "
      v-scroll="onScroll"
      v-show="fab"
      v-model="generalAttr.scrollTopButton"
      @click="toTop"
    />

    <v-footer
      v-if="currentPageAttr.footer && currentPageAttr.footer.data.active"
      v-bind="currentPageAttr.footer.data.attr"
      class="d-flex justify-space-between"
      ><div style="width: 70%">
        <div class="d-flex">
          <div>
            &copy; {{ new Date().getFullYear() }}
            {{ currentPageAttr.footer.data.text }}
          </div>
          <div style="margin-left: 20px">
            <small>({{ version }})</small>
          </div>
        </div>
      </div>
      <a href="https://www.foodyx.nl/" target="_blank">
        <img
          :src="`/asset/Foodyx_Logo_${currentPageAttr.footer.data.logoColor}_60x172.png`"
          :style="{ height: `${currentPageAttr.footer.data.logoHeight}px` }"
        />
      </a>
    </v-footer>
  </v-app>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters, mapState } from "vuex";
import PHeader from "./PHeader.vue";
import PButton from "../components/_render/PButton.vue";
import currentVersion from "../../version.json";

export default {
  props: {
    dataKey: String,
  },
  data: () => ({
    isCleared: false,
    tempData: {},
    isLoadingButton: false,
    fab: false,
  }),
  components: {
    PHeader,
    PButton,
  },
  methods: {
    ...mapActions({
      getThemeAll: "theme/getAll",
    }),

    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
  async mounted() {
    if (this.isThemeExpired) {
      console.log("reload theme");
      await this.getThemeAll();
    } else {
      this.$emit("input", this.currentPageAttr);
    }

    const func = async (event) => {
      if (event.data === "clearStorage") {
        await this.$store.dispatch("theme/getAll");
      } else {
        try {
          this.tempData = JSON.parse(event.data);
          console.log("RECEIVED", this.tempData);
        } catch (e) {
          console.log("ERROR", e, event);
        }
      }
    };
    if (window.location !== window.parent.location) {
      // under iframe
      window.addEventListener("message", func);
    }
  },
  computed: {
    version() {
      const { version, hash, branch } = currentVersion;
      return `v${version}  ${branch}-${hash}`;
    },

    ...mapState({
      dbData: (state) => state.theme.data,
    }),
    ...mapGetters({
      isThemeExpired: "theme/isExpired",
    }),
    dataFromDb() {
      const data = {};
      this.dbData.forEach((item) => {
        data[item.key] = item.value;
      });
      return data;
    },
    generalAttr() {
      const dbGeneral =
        this.dataFromDb &&
        this.dataFromDb.general &&
        this.dataFromDb.general.appBackground
          ? this.dataFromDb.general
          : {};
      const myData = _.merge(
        {
          ...dbGeneral,
        },
        this.tempData
      );
      return myData;
    },

    currentPageAttr() {
      const dbWelcome =
        this.dataFromDb && this.dataFromDb[this.dataKey]
          ? this.dataFromDb[this.dataKey]
          : {};
      const myData = _.merge(
        {
          ...dbWelcome,
        },
        this.tempData
      );
      return myData;
    },
  },
  watch: {
    currentPageAttr() {
      this.$emit("input", this.currentPageAttr);
    },
    dataFromDb: {
      handler() {
        this.$emit("input", this.currentPageAttr);
      },
      deep: true,
    },
  },
};
</script>

<style>
.v-footer {
  display: block !important;
}

.v-btn--fixed {
  position: fixed;
  z-index: 100;
}
</style>

