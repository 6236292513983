<template>
  <div>    
    <v-radio-group v-model="innerValue">
      <v-radio
        v-for="item in items"
        :key="item.id"
        :label="item.text"
        :value="item.value"
        dense
        hide-details
        :color="currentPageAttr.data.attr.style.color"
      />
    </v-radio-group>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
    value: String,
    max: Number,
    currentPageAttr: Object,
  },
  data() {
    return {
      innerValue: this.value,
      disable: false,
    };
  },
  computed: {
    disableMe() {
      if (this.innerValue && this.innerValue.length >= this.max) {
        return true;
      }
      return false;
    },
  },
  methods: {
    contains(item) {
      const selectedItems =
        typeof this.innerValue === "string"
          ? [this.innerValue]
          : this.innerValue;
      return selectedItems.includes(item);
    },
  },
  watch: {
    innerValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.innerValue = newValue;
    },
  },
};
</script>
